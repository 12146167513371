import React, { useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";

const CareerForm = ({ openPositions, selectedPosition }) => {
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const fileInputRef = useRef();
  const handleFile = (e) => {
    // console.log("----File:",e?.target?.files[0]?.name);
    setFileName(e?.target?.files[0]?.name);
    formik.setFieldValue("file", e?.target?.files[0]);
  };
  const supportedFileTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];

  const initialValues = {
    fullName: "",
    email: "",
    phone: "",
    applyingFor: "",
    file: null,
  };

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("This field is required"),
    email: Yup.string()
      .email("Invalid email")
      .required("This field is required"),
    phone: Yup.string().required("This field is required"),
    // applyingFor: Yup.string().required("This field is required"),
    file: Yup.mixed()
      .test("fileType", "Unsupported file format", (value) => {
        if (!value) return false;
        return supportedFileTypes.includes(value?.type);
      })
      .required("This field is required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true);
    // console.log("value:::",values);
    const formData = new FormData();
    formData.append("fullName", values.fullName);
    formData.append("email", values.email);
    formData.append("phone", values.phone);
    formData.append("applyingFor", selectedPosition);
    formData.append("file", values.file);
    // console.log("---",process.env.REACT_APP_API_BASEURL);

    const result = await axios.post(`${process.env.REACT_APP_CAREER}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (result.status == "200" || result?.status == "201") {
      toast.success("Form submitted successfully!");
      setFileName("");
      fileInputRef.current.value = null;
      resetForm();
      setLoading(false);
    } else {
      toast.error("Some error occur");
      setLoading(false);
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div className="container">
      <div className="row flex-row-reverse">
        <h5 className="text-center mb-4">Job Application Form</h5>
        <div className="col-lg-12 col-xl-12">
          <form
            className="form-style2 layout2 ajax-contact"
            // action=""
            // method="POST"
            onSubmit={formik.handleSubmit}
          >
            <div className="row">
              <div className="col-12 form-group">
                <label htmlFor="fullName">Full Name</label>
                <input
                  name="fullName"
                  id="fullName"
                  type="text"
                  placeholder="Full Name"
                  {...formik.getFieldProps("fullName")}
                  className={
                    formik?.touched?.fullName && formik?.errors?.fullName && ""
                  }
                />
                {formik?.touched?.fullName && formik?.errors?.fullName && (
                  <p className="text-danger">{formik?.errors?.fullName}</p>
                )}
              </div>
              <div className="col-md-6 form-group">
                <label htmlFor="email">Email Address</label>
                <input
                  name="email"
                  id="email"
                  type="email"
                  placeholder="Example@domain.com"
                  {...formik.getFieldProps("email")}
                  className={
                    formik?.touched?.email && formik?.errors?.email && ""
                  }
                />
                {formik?.touched?.email && formik?.errors?.email && (
                  <p className="text-danger">{formik?.errors?.email}</p>
                )}
              </div>
              <div className="col-md-6 form-group">
                <label htmlFor="phone">Phone No.</label>
                <input
                  name="phone"
                  id="phone"
                  type="number"
                  placeholder="0123456789"
                  {...formik.getFieldProps("phone")}
                  className={
                    formik?.touched?.phone && formik?.errors?.phone && ""
                  }
                />
                {formik?.touched?.phone && formik?.errors?.phone && (
                  <p className="text-danger">{formik?.errors?.phone}</p>
                )}
              </div>
           
              <div className="col-md-12 form-group">
                <label htmlFor="file">Upload CV</label>
                <div className="upload__file__wrapper">
                  <input
                    name="file"
                    id="file"
                    type="file"
                    onChange={handleFile}
                    accept=".pdf, .doc, .docx"
                    ref={fileInputRef}
                  />
                  <div className="upload__file">
                    {fileName !== "" &&
                    fileName !== null &&
                    fileName !== undefined
                      ? fileName
                      : "Choose File"}
                  </div>
                </div>
                {formik?.touched?.file && formik?.errors?.file && (
                  <p className="text-danger">{formik?.errors?.file}</p>
                )}
              </div>
              <div className="col-12 form-group">
                <button type="submit" className="vs-btn">
                  {loading === true ? "Sending..." : "Send"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CareerForm;
