import React, { useState } from "react";

const ProjectList = ({selectindex, projects,setLocation, selecteddrop }) => {
  const [openIndex, setOpenIndex] = useState(null);
console.log(selecteddrop)

  const toggleDescription = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  setLocation(selectindex)
  return <div className="project-list pointer">
  {projects &&
    projects.map((project, index) =>
      selecteddrop === project?.attributes.name ? (
        <div key={index} className="project-item" onClick={() => {toggleDescription(index); setLocation(index);}}>
          <b>{project.attributes.name}</b>
          { 
            <>
              <p>
                {project.attributes.energytype}
                {project.attributes.powerunit && (
                  <div>{project.attributes.watt} {project.attributes.powerunit}  IN PROGRESS</div>
                )}
                {/* {project.attributes.watt} */}
                {project.description}
              </p>
            </>
          }
        </div>
      ) : null
    )}
</div>

  
};

export default ProjectList;
