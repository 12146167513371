

import React, { useEffect, useState } from "react";
import BreadCrum from "../components/common/BreadCrum";
import { useNavigate } from "react-router-dom";
import ProjectList from "../components/ProjectList";
import ProjectMap from "../components/ProjectMap";
import Select from "react-dropdown-select";
import OurFootprintList from "./OurFootprintList";
import OurFootprintMap from "./OurFootprintMap";


const OurFootprint = () => {
  const latitude = "28.5355";
  const longitude = "77.3910";


  const navigate = useNavigate();

  const [projects, setProjects] = useState();
  const [iserror, setIsError] = useState(false);
  const [location, setLocation] = useState();
  const [markerPositions, setMarkerPositions] = useState([])
  const [options, setOptions] = useState([])
  const [dropvalues, setdrop] = useState()
  const [dropindex, setindex] = useState()


  // const markerPositions = [
    // { lat: 21.8243, lng: 76.3504, info: "Khandwa" },
    // { lat: 20.9026, lng: 74.7749,  info: "Dhule" },
    // { lat: 16.789, lng: 75.4321,  info: "Koppal" },
    // { lat: 20.0059, lng: 73.7749,  info: "Nashik" },
    // { lat: 17.65992, lng: 75.906387, info: "Solapur" },
  // ];

  // setValues(values) 
  //   // Assuming this function stores the selected value somewhere
  //    console.log(values);
 
  const setValues = (values)=>{
    // Assuming this function stores the selected value somewhere
    // const selectedValue = values.id; // or whatever property holds the selected value
    setindex(values[0].value)
    setdrop(values[0].label)
   }

  useEffect(() => {
     const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASEURL}/api/projects?populate=*`
        );
        const data = await response.json();

        // Assuming your data structure is similar, adjust accordingly
        setIsError(false);
        setOptions(data.data.map((item, index) => ({
          value: index,
          label: item.attributes.name
        })));
        
        setProjects(data.data);
        const markersArray = [(data?.data)?.map((project,i)=>{
      
          return { lat: Number(project?.attributes?.Latitude), lng: Number(project?.attributes?.Longitude), info : project?.attributes?.LocationName }
               
              })]
        setMarkerPositions(...markersArray)
        setLocation(0)
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsError(true);

        // Handle the error as needed, for example, set an error state
      }
    };

    fetchData();
  }, []);

  


  const handleProjectDetail = (projectId) => {
    navigate("/project-detail", { state: { id: `${projectId}` } });
  };

  return (
    <>

        <section className="space-top">
          <div className="">
    

          <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
                <div
                  className="title-area text-center wow fadeInUp wow-animated"
                  data-wow-delay="0.3s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.3s",
                    animationName: "fadeInUp",
                  }}
                >
                  <span className="sec-subtitle">Locations</span>
                  <h2 className="sec-title h1">Our Footprints</h2>
                </div>
              </div>



              <div className="container">
  <div className="row">
    <div className="col-md-8 ">
 <OurFootprintMap
          mPositions={markerPositions[location]}
          location={location}
          markePositions={markerPositions}
        />
    </div>
    <div className="col-md-4">


    <OurFootprintList projects={projects} setLocation={setLocation} selectindex={dropindex} selecteddrop={dropvalues} />
    </div>
  </div>

            </div>
            {iserror && (
              <>
                {" "}
                <h3 className="text-center text-secondary">
                  {" "}
                  Ooops! projects aren't Available{" "}
                </h3>{" "}
              </>
            )}
          </div>
        </section>
    </>
  );
};

export default OurFootprint;
