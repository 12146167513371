import React from "react";

const WhyChooseUs2 = () => {
  return (
    <>
      <section className="space-top space-extra-bottom">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.4s">
            <span className="sec-subtitle">Why you choose us</span>
            <h2 className="sec-title h1">Renewable Energy Solution</h2>
          </div>
          <div className="row">
            <div
              className="col-xl-6 mb-40 mb-xl-0 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="img-bo x3 pt-5">
                <div className="img-1">
                  <img src="assets/img/about/5.jpeg" alt="about" />
                </div>

                {/* <div className="shape-dotted jump"></div> */}
              </div>
            </div>
            <div
              className="col-xl-6 align-self-center wow fadeInUp"
              data-wow-delay="0.4s"
            >
              <div className="" style={{marginTop:"60px"}}>
                {/* <p className="about-text">Datta Infra is one of the emerging Renewable Energy developer players in India catering to the country’s top renewable corporates.</p> */}
                <div className="media-style2">
                  <div class="media-icon">
                    <i class="far fa-check"></i>
                  </div>{" "}
                  <div className="media-body">
                    <h3 className="media-title h6">
                      Expertise in Renewable Energy:{" "}
                    </h3>
                    <p className="media-info wcu-text">
                      Datta Infra has established itself as a leader in the
                      renewable energy sector, offering comprehensive solutions
                      from project development to EPC (Engineering, Procurement,
                      and Construction) projects. With years of experience and a
                      team of seasoned professionals, we ensure that every
                      project meets the highest standards of quality and
                      efficiency.{" "}
                    </p>
                  </div>
                </div>
                <div className="media-style2">
                  <div class="media-icon">
                    <i class="far fa-check"></i>
                  </div>
                  <div className="media-body">
                    <h3 className="media-title h6">
                      Comprehensive Service Portfolio:
                    </h3>
                    <p className="media-info wcu-text">
                      Our diversified services include the engineering,
                      procurement, and construction of transmission lines, solar
                      parks, wind parks, and operation and maintenance services.
                      This broad spectrum allows us to cater to various needs
                      within the energy infrastructure sector, providing
                      end-to-end solutions.
                    </p>
                  </div>
                </div>
                <div className="media-style2">
                  <div class="media-icon">
                    <i class="far fa-check"></i>
                  </div>
                  <div className="media-body">
                    <h3 className="media-title h6">Proven Track Record: </h3>
                    <p className="media-info wcu-text">
                      With over 3000 MW of land acquisition and project
                      development completed, and an ongoing pipeline of 8000 MW
                      of renewable energy projects, Datta Infra has a proven
                      track record of executing successful and large-scale
                      projects. Our extensive experience ensures reliability and
                      excellence in every endeavor.
                    </p>
                  </div>
                </div>
                <div className="media-style2">
                  <div class="media-icon">
                    <i class="far fa-check"></i>
                  </div>
                  <div className="media-body">
                    <h3 className="media-title h6">
                      Strong Industry Recognition:
                    </h3>
                    <p className="media-info wcu-text">
                      Our dedication to excellence has earned us numerous
                      accolades and industry recognition, reinforcing our
                      position as a trusted and reputable company in the energy
                      sector.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
          <div
          className="col-xl-6 mb-40 mb-xl-0 wow fadeInUp"
          data-wow-delay="0.3s"
        >
          <div className="img-bo x3 pt-5">
            <div className="img-1">
              <img src="assets/img/about/why-choose-1.jpeg" alt="about" />
            </div>

            {/* <div className="shape-dotted jump"></div> */}
          </div>
        </div>
            <div
              className="col-xl-6 align-self-center wow fadeInUp"
              data-wow-delay="0.4s"
            >
              <div className=" " style={{marginTop:"60px"}}>
                {/* <p className="about-text">Datta Infra is one of the emerging Renewable Energy developer players in India catering to the country’s top renewable corporates.</p> */}
                <div className="media-style2">
                  <div class="media-icon">
                    <i class="far fa-check"></i>
                  </div>
                  <div className="media-body">
                    <h3 className="media-title h6">Commitment to Quality:</h3>
                    <p className="media-info wcu-text">
                      Datta Infra is committed to maintaining the highest
                      standards of quality, as evidenced by our ISO 14001:2015,
                      ISO 9001:2015, and ISO 45001:2018 certifications. These
                      certifications demonstrate our adherence to stringent
                      quality standards and comprehensive compliance practices,
                      ensuring exceptional performance in all our projects.
                    </p>
                  </div>
                </div>

                <div className="media-style2">
                  <div class="media-icon">
                    <i class="far fa-check"></i>
                  </div>
                  <div className="media-body">
                    <h3 className="media-title h6">
                      {" "}
                      Innovation and Sustainability:{" "}
                    </h3>
                    <p className="media-info wcu-text">
                      We are at the forefront of innovation in the renewable
                      energy sector, continuously adopting cutting-edge
                      technologies to drive sustainable development. Our
                      projects contribute significantly to reducing carbon
                      footprints and promoting clean energy, aligning with
                      global sustainability goals.
                    </p>
                  </div>
                </div>
                <div className="media-style2">
                  <div class="media-icon">
                    <i class="far fa-check"></i>
                  </div>
                  <div className="media-body">
                    <h3 className="media-title h6">
                      Strategic Growth and Expansion:{" "}
                    </h3>
                    <p className="media-info wcu-text">
                      As part of our growth strategy, Datta Infra is expanding
                      into the Independent Power Producer (IPP) business. With
                      plans to develop over 1 GW of renewable energy projects in
                      the next three years, we are committed to contributing to
                      India&apos;s energy security and sustainable development
                      on a national scale.
                    </p>
                  </div>
                </div>
                <div className="media-style2">
                  <div class="media-icon">
                    <i class="far fa-check"></i>
                  </div>
                  <div className="media-body">
                    <h3 className="media-title h6">
                      Customer-Centric Approach:{" "}
                    </h3>
                    <p className="media-info wcu-text">
                      At Datta Infra, we prioritize our client&apos;s needs,
                      offering tailored solutions that ensure maximum efficiency
                      and value. Our client-centric approach ensures that we
                      deliver projects that not only meet but exceed
                      expectations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhyChooseUs2;
