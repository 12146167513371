import React from "react";

export const WhyChoose4 = () => {
  return (
    <section className="overflow-hidden bg-light-1 space-top">
      <div className="container">
        <div className="title-area mb-3 pb-5 text-center">
          <span className="sec-subtitle">WHY CHOOSE US</span>
          <h2 className="sec-title h1">We are Building a Sustainable Future</h2>
        </div>
        <div className="row gx-60 flex-row-reverse justify-content-between align-items-center">
          <div
            className="col-lg-5 col-xl-6 z-index-common wow fadeInUp"
            data-wow-delay="0.3s"
          >
            <div className="img-box7">
              <div className="img-1">
                <img src="assets/img/about/ab-32.jpeg" alt="about thumbnail" />
              </div>
            </div>
          </div>
          <div
            className="col-lg-7 col-xl-6 space py-xl-0 wow fadeInUp"
            data-wow-delay="0.4s"
          >
            <div
              className="accordion accordion-style2 space-bottom"
              id="faqVersion1"
            >
              <div className="accordion-item">
                <div className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Proven Track Record
                  </button>
                </div>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#faqVersion1"
                >
                  <div className="accordion-body">
                    <p>
                      {" "}
                      With years of experience and a track record of successful
                      projects, Datta Infra stands as a trusted name in the
                      renewable energy sector. Our history of delivering
                      India&apos;s largest solar projects and securing orders
                      for wind and hybrid projects, including under prestigious
                      schemes like KUSUM, demonstrates our expertise and
                      reliability.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <div className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Industry Leadership
                  </button>
                </div>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#faqVersion1"
                >
                  <div className="accordion-body">
                    <p>
                      Led by seasoned professionals, Datta Infra is at the
                      forefront of renewable project development, transmission
                      line construction, substations, and EPC projects on a
                      turnkey basis. Our specialization in renewable energy,
                      particularly solar and wind, sets us apart as leaders in
                      the field.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <div className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Commitment to Sustainability
                  </button>
                </div>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#faqVersion1"
                >
                  <div className="accordion-body">
                    <p>
                      At Datta Infra, we are committed to fostering
                      sustainability and advancing India&apos;s clean energy
                      landscape. Our dedication to developing over 1 GW of
                      renewable energy projects in the next two years as an
                      Independent Power Producer (IPP) underscores our
                      commitment to driving positive change.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <div className="accordion-header" id="headingFour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Expertise in Transmission Lines
                  </button>
                </div>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#faqVersion1"
                >
                  <div className="accordion-body">
                    <p>
                      Datta Infra exhibits unmatched expertise in transmission
                      line construction, having completed significant projects
                      such as a 400 KV S/C line for SJVNL&apos;s 1 GW project
                      and a 220 KV transmission line for Tata Power Renewable
                      Energy Limited. Our proficiency in this area ensures
                      seamless integration of renewable energy into the grid.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <div className="accordion-header" id="headingFive">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Strategic Partnerships
                  </button>
                </div>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#faqVersion1"
                >
                  <div className="accordion-body">
                    <p>
                      Our strategic partnership with industry giants like Tata
                      Power demonstrates the trust and confidence placed in
                      Datta Infra&apos;s capabilities. Entrusted with EPC
                      services for solar projects in Rajasthan, our partnership
                      with Tata Power reflects their recognition of our
                      expertise and commitment to excellence.
                    </p>
                  </div>
                </div>
              </div>{" "}
              <div className="accordion-item">
                <div className="accordion-header" id="headingSix">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Expansion and Growth
                  </button>
                </div>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#faqVersion1"
                >
                  <div className="accordion-body">
                    <p>
                      Datta Infra&apos;s successful completion of EPC
                      assignments has led to further projects in key regions
                      such as Rajasthan, Tamil Nadu, and Maharashtra. Securing
                      contracts of 10 GW from corporate giants like Aditya
                      Birla, Tata, Engie, and PSUs like SJVN and NTPC,
                      underscores our commitment to growth and expansion.
                    </p>
                  </div>
                </div>
              </div>{" "}
              <div className="accordion-item">
                <div className="accordion-header" id="headingSeven">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSeven"
                    aria-expanded="false"
                    aria-controls="collapseSeven"
                  >
                    Reliability and Performance
                  </button>
                </div>
                <div
                  id="collapseSeven"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSeven"
                  data-bs-parent="#faqVersion1"
                >
                  <div className="accordion-body">
                    <p>
                      We take pride in our ability to deliver projects within or
                      ahead of timelines, demonstrating reliability and
                      professionalism in every aspect of our work. Our confirmed
                      order book exceeding Rs.1700 crores speaks volumes about
                      our performance and credibility in the industry.
                    </p>
                  </div>
                </div>
              </div>{" "}
              <div className="accordion-item">
                <div className="accordion-header" id="headingFour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseEight"
                    aria-expanded="false"
                    aria-controls="collapseEight"
                  >
                    Contribution to Sustainable Development
                  </button>
                </div>
                <div
                  id="collapseEight"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingEight"
                  data-bs-parent="#faqVersion1"
                >
                  <div className="accordion-body">
                    <p>
                      By choosing Datta Infra, clients contribute to the
                      development of renewable energy infrastructure and ease
                      project setup challenges for corporate and public sector
                      clients. Together, we are driving positive change and
                      making a meaningful impact on the environment and society.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
