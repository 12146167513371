

import React, { useState } from "react";

const OurFootprintList = ({selectindex, projects,setLocation ,selecteddrop}) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleDescription = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    setLocation(selectindex)
  };
  return (<div className="footprint-project-list pointer">
  {projects &&
    projects.map((project, index) =>
  
        <div key={index} className="project-item" onClick={() => {toggleDescription(index); setLocation(index);}}>
          <b>{project.attributes.name}</b>
          { openIndex == index &&
            <>
              <p>
                {project.attributes.energytype}
                {project.attributes.powerunit && (
                  <div>{project.attributes.watt} {project.attributes.powerunit} IN PROGRESS</div>
                )}
                {/* {project.attributes.watt} */}
                {project.description}
              </p>
            </>
          }
        </div>
    
    )}
</div>

  );
};

export default OurFootprintList;
