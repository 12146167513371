import React, { useEffect, useState } from 'react';
import pinImg from "../pin.png";
import { Loader } from '@googlemaps/js-api-loader';

const Gmap = () => {
  const [projects, setProjects] = useState();
  const [iserror, setIsError] = useState(false);
  const [location, setLocation] = useState();
  const [markerPositions, setMarkerPositions] = useState([])
  const [options, setOptions] = useState([])

  useEffect(() => {
    const fetchData = async () => {
     try {
       const response = await fetch(
         `${process.env.REACT_APP_BASEURL}/api/projects?populate=*`
       );
       const data = await response.json();

       // Assuming your data structure is similar, adjust accordingly
       if(options != null)
        setOptions(data.data.map((item, index) => ({
        value: index,
        lat: parseFloat(item.attributes.Latitude),
        lng: parseFloat(item.attributes.Longitude),
        info: item.attributes.LocationName
    })));
    
       setProjects(data.data);
       const markersArray = [(data?.data)?.map((project,i)=>{
     
         return { lat: Number(project?.attributes?.Latitude), lng: Number(project?.attributes?.Longitude), info : project?.attributes?.LocationName }
              
             })]
       setMarkerPositions(...markersArray)
       setLocation(0)
     } catch (error) {
       console.error("Error fetching data:", error);
       setIsError(true);

       // Handle the error as needed, for example, set an error state
     }
   };

   fetchData();
 }, []);


const loader = new Loader({
  apiKey: "AIzaSyAHfpaBgpYA1LeUKJ02GYNEvBbNJNy9-BA",
  version: "weekly",
  libraries: ["places"],
});
const centerCoordinates = { lat: 19.846811, lng: 75.890633 };
const mapStyles =[
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.business",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dadada"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "transit",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#c9c9c9"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  }
]
const mapOptions = {
  zoom: 6,
  center: centerCoordinates,
  styles: mapStyles
};
  useEffect(() => {
    loader
    .load()
    .then((google) => {
      const map = new google.maps.Map(document.getElementById("map"), mapOptions);
      const infoWindow = new google.maps.InfoWindow({
        disableAutoPan: true
      });
      const markerPositions = [
        // { lat: 26.9124, lng: 70.9138, color: 'pink', info: 'Jaisalmer' },
        // { lat: 28.0229, lng: 73.3119, color: 'blue', info: 'Bikaner' },
        // { lat: 24.4700, lng: 74.8588, color: 'purple', info: 'Neemach' },
        { lat: 21.8243, lng: 76.3504, color: 'orange', info: 'Khandwa' },
        { lat: 20.9026, lng: 74.7749, color: 'green', info: 'Dhule' },
        { lat: 16.7890, lng: 75.4321, color: 'red', info: 'Koppal' },
        { lat: 20.0059, lng: 73.7749, color: 'yellow', info: 'Nashik' },
        { lat: 17.659920, lng: 75.906387, color: 'blue', info: 'Solapur' },
      ];
      const icon = {
        url:"/assets/img/security-pin_6125244.png",
        // url:  pinImg,
        scaledSize: new google.maps.Size(25, 25),
      };
      options.forEach((position, index) => {
        let marker = new google.maps.Marker({
          position: position,
          map: map,
          title: `Marker ${index + 1}`,
          icon: icon
        });
        // Add event listener for marker hover
        marker.addListener('mouseover', () => {
          infoWindow.setContent(position.info);
          infoWindow.open(map, marker);
        });
        // Close info window on marker mouseout
        marker.addListener('mouseout', () => {
          infoWindow.close();
        });
        marker.setMap(map);
      })
    })
    .catch(e => {
      // do something
      console.error("Map Error:",e);
    });


    
   
    // Check if the Google Maps API has been loaded
    // if (window.google) {
    //   // If it's already loaded, initialize the map
    //   const initMap = () => {
    //     // Specify the coordinates for the center of the map
    //     const centerCoordinates = { lat: 19.846811, lng: 75.890633 };
  
    //     // Custom map styles
    //     const mapStyles = [
    //       {
    //         featureType: "all",
    //         elementType: "labels.text",
    //         stylers: [{ color: "#878787" }]
    //       },
    //       {
    //         featureType: "all",
    //         elementType: "labels.text.stroke",
    //         stylers: [{ visibility: "off" }]
    //       },
    //       {
    //         featureType: "landscape",
    //         elementType: "all",
    //         stylers: [{ color: "#f9f5ed" }]
    //       },
    //       {
    //         featureType: "road.highway",
    //         elementType: "all",
    //         stylers: [{ color: "#f5f5f5" }]
    //       },
    //       {
    //         featureType: "road.highway",
    //         elementType: "geometry.stroke",
    //         stylers: [{ color: "#c9c9c9" }]
    //       },
    //       {
    //         featureType: "water",
    //         elementType: "all",
    //         stylers: [{ color: "#aee0f4" }]
    //       }
    //     ];
  
    //     // Create a new map with custom styles
    //     const map = new window.google.maps.Map(document.getElementById('map'), {
    //       zoom: 7,
    //       center: centerCoordinates,
    //       styles: mapStyles  // Apply the custom styles to the map
    //     });
  
    //     // Array of marker positions
    //     const markerPositions = [
    //       // { lat: 26.9124, lng: 70.9138, color: 'pink', info: 'Jaisalmer' },
    //       // { lat: 28.0229, lng: 73.3119, color: 'blue', info: 'Bikaner' },
    //       // { lat: 24.4700, lng: 74.8588, color: 'purple', info: 'Neemach' },
    //       { lat: 21.8243, lng: 76.3504, color: 'orange', info: 'Khandwa' },
    //       { lat: 20.9026, lng: 74.7749, color: 'green', info: 'Dhule' },
    //       { lat: 16.7890, lng: 75.4321, color: 'red', info: 'Koppal' },
    //       { lat: 20.0059, lng: 73.7749, color: 'yellow', info: 'Nashik' },
    //       { lat: 17.659920, lng: 75.906387, color: 'blue', info: 'Solapur' },
    //     ];
        
    //     // Create info window with disableAutoPan option
    //     const infoWindow = new window.google.maps.InfoWindow({
    //       disableAutoPan: true
    //     });
  
    //     // Loop through the array and add markers to the map
    //     const icon = {
    //       url: pinImg,
    //       scaledSize: new google.maps.Size(50, 50),
    //     };
    //     markerPositions.forEach((position, index) => {
    //       const marker = new window.google.maps.Marker({
    //         position: position,
    //         map: map,
    //         title: `Marker ${index + 1}`,
    //         icon: icon
    //       });
  
    //       // Add event listener for marker hover
    //       marker.addListener('mouseover', () => {
    //         infoWindow.setContent(position.info);
    //         infoWindow.open(map, marker);
    //       });
  
    //       // Close info window on marker mouseout
    //       marker.addListener('mouseout', () => {
    //         infoWindow.close();
    //       });
    //     });
    //   };
    //   window.initMap = initMap
    //   initMap();
    // } 
    // else {
    //   // If not, load the Google Maps API script
    //   const script = document.createElement('script');
    //   script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAHfpaBgpYA1LeUKJ02GYNEvBbNJNy9-BA&callback=initMap`;
    //   script.defer = true;
    //   document.head.appendChild(script);

    //   // Ensure that the initMap function is available globally
    //   window.initMap = initMap;
    // }

    // Clean up the script tag when the component is unmounted
    // return () => {
    //   const scriptTag = document.querySelector('script[src^="https://maps.googleapis.com/maps/api/js"]');
    //   if (scriptTag) {
    //     scriptTag.remove();
    //   }
    // };
  }, [options]);

  return (
    // Map container
    <div id="map" style={{ marginTop: "80px", height: '650px', width: '100%' }}></div>
  );
};

export default Gmap;
