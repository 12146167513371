import React from "react";

export const Corevalue = () => {
  return (
    <div className="container text-black p-8 space-extra-bottom">
      <div className="text-center">
        <h1>The Core Value</h1>
      </div>
      <div className="text-center">
        <p>
          The core values of our organization underlie all our work & strategies
          that we deploy to accomplish our vision & mission.
        </p>
      </div>
      <div className="row core">
        <div className="col-md-3">
          <img
            src="assets/img/about/integrity.webp"
            alt="Integrity"
            className="mb-2"
          />
          <div>Integrity</div>
        </div>
        <div className="col-md-3">
          <img
            src="assets/img/about/respect.webp"
            alt="Respect for Individuals"
            className="mb-2"
          />
          <div>Respect for Individuals</div>
        </div>
        <div className="col-md-3">
          <img
            src="assets/img/about/customer.webp"
            alt="Customer First"
            className="mb-2"
          />
          <div>Customer First</div>
        </div>
        <div className="col-md-3">
          <img
            src="assets/img/about/passion.webp"
            alt="Passion for Excellence"
            className="mb-2"
          />
          <div>Passion for Excellence</div>
        </div>
      </div>
    </div>
  );
};
