import React, { useRef,useState,useEffect } from "react";
import Gmap from "./Gmap";


const Footprint = () => {







  const [myRef1, myRef2, myRef3, myRef4, myRef5, myRef6, myRef7, myRef8] =
    Array.from({ length: 8 }, () => useRef());

  const [
    mySpanRef1,
    mySpanRef2,
    mySpanRef3,
    mySpanRef4,
    mySpanRef5,
    mySpanRef6,
    mySpanRef7,
    mySpanRef8,
  ] = Array.from({ length: 8 }, () => useRef());

  const applyHoverEffect = (myRef, mySpanRef) => {
    if (myRef.current) {
      myRef.current.style.width = "100%";
      mySpanRef.current.style.color = "white";
    }
  };

  const removeHoverEffect = (myRef, mySpanRef, textcolor) => {
    if (myRef.current) {
      myRef.current.style.width = "21%";
      mySpanRef.current.style.color = textcolor;
    }
    // setCircleWidth('21%');
    // setButtonTextColor(textColor);
  };

  return (
    <>
      <div className="container">
        <section className=" space-top space-extra-bottom">
          <div className="container">
            <div className="">
              <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
                <div
                  className="title-area text-center wow fadeInUp wow-animated"
                  data-wow-delay="0.3s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.3s",
                    animationName: "fadeInUp",
                  }}
                  // style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;"
                >
                  <span className="sec-subtitle">Locations</span>
                  <h2 className="sec-title h1">Our Footprints</h2>
                </div>
              </div>
              <div className="row">
       
                <div className="col-lg-12">
    <Gmap /> 


                </div>

             
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Footprint;
