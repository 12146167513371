import React from 'react'
import BreadCrum from '../components/common/BreadCrum'
import ContactForm from '../components/form/ContactForm'
import GoogleMap from '../components/GoogleMap'
import Clients from '../components/Clients'
import Newsletter from '../components/newsletter'

const Contact = () => {
  return (
   <>
<BreadCrum title={"Contact Us"} slogan={"Building Tomorrow's World: Datta Infra's Dedication to Renewable Energy Excellence"} />
<ContactForm />
<GoogleMap/>
<Clients/>
   
   </>
  )
}

export default Contact