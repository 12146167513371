import React,{useState, useEffect,useRef} from "react";
import $ from 'jquery';
import 'slick-carousel/slick/slick'; // Ensure you have the correct path to slick.css or slick-theme.css if needed

const HeroSection = () => {
 
  return (

      <section>
        <div 
          className="pbmit-slider-area"
  
        >
          {/* <!-- START homeslider1 REVOLUTION SLIDER 6.5.20 --> */}
          <p className="rs-p-wp-fix"></p>
          <rs-module-wrap
            id="rev_slider_1_1_wrapper"
            data-alias="homeslider1"
            data-pause-on-hover="false"
            data-source="gallery"
            style={{
              // visibility: "hidden",
              background: "transparent",
              padding: 0,
              margin: "0 auto",
              marginTop: 0,
              marginBottom: 0,
            }}
          >
            <rs-module id="rev_slider_1_1" style={{}} data-version="6.5.20">
              <rs-slides>
                <rs-slide
                  style={{ position: "absolute" }}
                  data-key="rs-3"
                  data-title="Slide"
                  data-thumb=""
                  data-duration="3500"
                  data-anim="ms:200;"
                  data-in="o:0;"
                  data-out="a:false;"
                  data-pause-on-hover="false"
                >
                  <img
                    src="revolution/images/animated-slider-bg-3.jpg"
                    alt=""
                    title="animated-slider-bg-3"
                    width="1600"
                    height="750"
                    className="rev-slidebg tp-rs-img"
                    data-bg="p:center bottom;"
                    data-no-retina
                  />
                  {/* <rs-layer
                    id="slider-2-slide-3-layer-1"
                    data-type="text"
                    data-color="#222222"
                    data-rsp_ch="on"
                    data-xy="xo:30px;yo:180px,180px,180px,60px;"
                    data-text="s:14,14,14,12;l:22,18,18,22;ls:2px;fw:700,400,700,700;a:inherit;"
                    data-padding="t:5,5,5,3;r:20,20,20,5;b:5,5,5,3;l:20,20,20,5;"
                    data-frame_0="o:1;"
                    data-frame_0_sfx="se:blocktoright;"
                    data-frame_1="e:power4.inOut;st:290;sp:2000;sR:290;"
                    data-frame_1_sfx="se:blocktoright;"
                    data-frame_999="o:0;st:w;sR:7730;"
                    style={{
                      zIndex: 13,
                      backgroundColor: "#ffffff",
                      fontFamily: "Roboto",
                      textTransform: "uppercase",
                    }}
                  >
                    Emerging Renewable Energy
                  </rs-layer> */}
                  

                  <rs-layer
                  data-pause-on-hover="false"
                    id="slider-2-slide-3-layer-2"
                    data-type="text"
                    data-color="#fff"
                    data-rsp_ch="on"
                    data-xy="xo:30px;yo:230px,230px,230px,110px;"
                    data-text="s:55,45,45,28;l:60,52,52,34;fw:700;a:inherit;"
                    data-frame_0="x:-100%;o:1;"
                    data-frame_0_mask="u:t;"
                    data-frame_1="st:1960;sp:1000;sR:1960;"
                    data-frame_1_mask="u:t;"
                    data-frame_999="o:0;st:w;sR:6560;"
                    style={{
                      zIndex: 14,
                      fontFamily: "Asap",
                    }}
                  >
                  Solar, Wind, and Beyond:<br/> Transforming Energy Landscape

                  </rs-layer>
                  <rs-layer
                  
                  
                  data-pause-on-hover="false"
                    id="slider-2-slide-3-layer-6"
                    data-type="text"
                    data-color="#fff"
                    data-rsp_ch="on"
                    data-xy="xo:30px;yo:375px,360px,360px,190px;"
                    data-text="s:20,24,20,16;l:30,30,30,20;fw:700;a:inherit;"
                    data-frame_0="x:-100%;o:1;"
                    data-frame_0_mask="u:t;"
                    data-frame_1="st:1960;sp:1500;sR:1960;"
                    data-frame_1_mask="u:t;"
                    data-frame_999="o:0;st:w;sR:6560;"
                    style={{ zIndex: "14", fontFamily: "Asap" }}
                  >
                  Datta Power Infra: Illuminating the Path Ahead

                  </rs-layer>
                  <rs-layer
                  data-pause-on-hover="false"
                    id="slider-2-slide-3-layer-5"
                    className="rev-btn"
                    data-type="button"
                    data-color="#fff"
                    data-rsp_ch="on"
                    data-xy="xo:30px;yo:465px,445px,445px,250px;"
                    data-text="s:13,13,13,12;l:30;ls:1px;fw:600;a:inherit;"
                    data-padding="t:12,8,8,5;r:45,30,30,20;b:12,8,8,5;l:45,30,30,20;"
                    data-border="bor:2px,2px,2px,2px;"
                    data-frame_1="st:3410;sp:500;sR:3410;"
                    data-frame_999="o:0;st:w;sR:6110;"
                    // data-frame_hover="c:#fff;bgc:#47af34;boc:#000;bor:3px,3px,3px,3px;bos:solid;oX:50;oY:50;sp:0;"
                  >
                    {/* <div className="ls-btn-group">
            <a href="/contact" className="vs-btn">
              Contact Us
            </a>
          </div> */}
                  </rs-layer>
                  <rs-layer
                  data-pause-on-hover="false"

                    id="slider-2-slide-3-layer-9"
                    data-type="image"
                    data-rsp_ch="on"
                    data-xy="x:r;xo:278px,178px,178px,-581px;yo:19px,19px,19px,-231px;"
                    data-text="l:22;a:inherit;"
                    data-dim="w:['392px','392px','392px','392px'];h:['392px','392px','392px','392px'];"
                    data-vbility="t,t,t,f"
                    data-basealign="slide"
                    data-frame_1="sR:10;"
                    data-frame_999="st:w;sR:9710;"
                    data-loop_0="oX:50;oY:50;"
                    data-loop_999="rZ:360;sp:5000;yym:t;yys:t;yyf:t;"
                    style={{ zIndex: "12" }}
                  >
                    <img
                      src="revolution/images/windmill-last.png"
                      alt=""
                      className="tp-rs-img"
                      width="910"
                      height="910"
                      data-no-retina
                    />
                  </rs-layer>
                  <rs-layer
                  data-pause-on-hover="false"
                    id="slider-2-slide-3-layer-10"
                    data-type="image"
                    data-rsp_ch="on"
                    data-xy="x:r;xo:0,0,0,-50px;y:b;yo:-24px,-24px,-24px,-15px;"
                    data-text="l:22;a:inherit;"
                    data-dim="w:875px,701px,701px,364px;h:423px,339px,339px,176px;"
                    data-basealign="slide"
                    data-frame_1="sR:10;"
                    data-frame_999="st:w;sR:9710;"
                    style={{ zIndex: "9" }}
                  >
                    <img
                      src="revolution/images/slider-1-part-01.png"
                      alt=""
                      className="tp-rs-img"
                      width="875"
                      height="423"
                      data-no-retina
                    />
                  </rs-layer>
                  <rs-layer
                  data-pause-on-hover="false"
 
                    id="slider-2-slide-3-layer-11"
                    data-type="image"
                    data-rsp_ch="on"
                    data-xy="x:r;xo:230px,130px,130px,-326px;yo:202px,202px,202px,-87px;"
                    data-text="l:22;a:inherit;"
                    data-dim="w:['417px','417px','417px','417px'];h:['403px','403px','403px','403px'];"
                    data-basealign="slide"
                    data-frame_1="sR:10;"
                    data-frame_999="st:w;sR:9710;"
                    style={{ zIndex: "8" }}
                  >
                    <img
                      src="revolution/images/slider-1-part-02.png"
                      alt=""
                      className="tp-rs-img"
                      width="417"
                      height="403"
                      data-no-retina
                    />
                  </rs-layer>
                  <rs-layer
                  data-pause-on-hover="false"
                    id="slider-2-slide-3-layer-13"
                    data-type="image"
                    data-rsp_ch="on"
                    data-xy="x:r;xo:111px,11px,11px,-14px;yo:122px,122px,122px,23px;"
                    data-text="l:22;a:inherit;"
                    data-dim="w:263px,263px,263px,193px;h:263px,263px,263px,193px;"
                    data-basealign="slide"
                    data-frame_1="sR:10;"
                    data-frame_999="st:w;sR:9710;"
                    data-loop_0="oX:50;oY:50;"
                    data-loop_999="rZ:360;sp:5000;yym:t;yys:t;yyf:t;"
                    style={{ zIndex: "10" }}
                  >
                    <img
                      src="revolution/images/windmill-last.png"
                      alt=""
                      className="tp-rs-img"
                      width="910"
                      height="910"
                      data-no-retina
                    />
                  </rs-layer>

                  <rs-layer
                  data-pause-on-hover="false"
                    id="slider-2-slide-3-layer-14"
                    data-type="image"
                    data-rsp_ch="on"
                    data-xy="x:r;xo:530px,430px,430px,728px;yo:304px,304px,304px,177px;"
                    data-text="l:22;a:inherit;"
                    data-dim="w:['214px','214px','214px','214px'];h:['214px','214px','214px','214px'];"
                    data-vbility="t,t,t,f"
                    data-basealign="slide"
                    data-frame_1="sR:10;"
                    data-frame_999="st:w;sR:9710;"
                    data-loop_0="oX:50;oY:50;"
                    data-loop_999="rZ:360;sp:5000;yym:t;yys:t;yyf:t;"
                    style={{ zIndex: "11" }}
                  >
                    <img
                      src="revolution/images/windmill-last.png"
                      alt=""
                      className="tp-rs-img"
                      width="910"
                      height="910"
                      data-no-retina
                    />
                  </rs-layer>{" "}
                </rs-slide>
                <rs-slide
                  style={{ position: "absolute" }}
                  data-pause-on-hover="false"
                  data-key="rs-2"
                  data-title="Slide"
                  data-thumb="revolution/images/animated-slider-3.jpg"
                  data-duration="3600"
                  data-in="o:0;"
                  data-out="a:false;"
                >
                  <img
                    src="revolution/images/animated-slider-3.jpg"
                    alt=""
                    title="animated-slider-3"
                    width="1900"
                    height="850"
                    className="rev-slidebg tp-rs-img"
                    data-bg="p:center bottom;"
                    data-no-retina
                  />
                  {/* <rs-layer
                    id="slider-2-slide-2-layer-1"
                    data-type="text"
                    data-color="#191919||#191919||#191919||#222222"
                    data-rsp_ch="on"
                    data-xy="xo:30px,52px,52px,30px;yo:180px,180px,180px,60px;"
                    data-text="s:14,14,14,12;l:22,18,18,22;ls:2px;fw:700;a:inherit;"
                    data-padding="t:5,5,5,3;r:20,20,20,5;b:5,5,5,3;l:20,20,20,5;"
                    data-frame_0="o:1;"
                    data-frame_0_sfx="se:blocktoright;fxc:#0a0a0a;"
                    data-frame_1="e:power4.inOut;st:320;sp:2000;sR:320;"
                    data-frame_1_sfx="se:blocktoright;fxc:#0a0a0a;"
                    data-frame_999="o:0;st:w;sR:7680;"
                    style={{
                      zIndex: 10,
                      backgroundColor: "#ffffff",
                      fontFamily: "Roboto",
                      textTransform: "uppercase",
                    }}
                  >
                    Best Quality Solar
                  </rs-layer> */}
                  <rs-layer
                  data-pause-on-hover="false"
                    id="slider-2-slide-2-layer-2"
                    data-type="text"
                    data-color="#fff"
                    data-rsp_ch="on"
                    data-xy="xo:30px,52px,52px,30px;yo:230px,230px,230px,110px;"
                    data-text="s:55,45,45,28;l:60,52,52,34;fw:700;a:inherit;"
                    data-frame_0="x:-100%;o:1;"
                    data-frame_0_mask="u:t;"
                    data-frame_1="st:1960;sp:1000;sR:1960;"
                    data-frame_1_mask="u:t;"
                    data-frame_999="o:0;st:w;sR:6540;"
                    style={{ zIndex: "11", fontFamily: "Asap" }}
                  >
                  Empowering Tomorrow with<br/> Sustainable Solutions
                  </rs-layer>
                  <rs-layer
                  data-pause-on-hover="false"
                    id="slider-2-slide-2-layer-6"
                    data-type="text"
                    data-color="#fff"
                    data-rsp_ch="on"
                    data-xy="xo:30px,52px,52px,30px;yo:375px,355px,355px,190px;"
                    data-text="s:20,24,20,14;l:30,52,52,34;fw:700;a:inherit;"
                    data-frame_0="x:-100%;o:1;"
                    data-frame_0_mask="u:t;"
                    data-frame_1="st:1960;sp:1500;sR:1960;"
                    data-frame_1_mask="u:t;"
                    data-frame_999="o:0;st:w;sR:6560;"
                    style={{ zIndex: "14", fontFamily: "Asap" }}
                  >
                  Innovating Energy Infrastructure for a Brighter World
                  </rs-layer>
                  <rs-layer
                    id="slider-2-slide-2-layer-5"
                    className="rev-btn"
                    data-type="button"
                    data-color="#fff"
                    data-rsp_ch="on"
                    data-xy="xo:30px,52px,52px,30px;yo:465px,425px,425px,250px;"
                    data-text="s:13,13,13,12;l:30;ls:1px;fw:600;a:inherit;"
                    data-padding="t:12,8,8,5;r:45,30,30,20;b:12,8,8,5;l:45,30,30,20;"
                    data-border="bor:3px,3px,3px,3px;"
                    data-frame_1="st:3600;sp:500;sR:3600;"
                    data-frame_999="o:0;st:w;sR:5900;"
                    // data-frame_hover="c:#fff;bgc:#47af34;boc:#000;bor:3px,3px,3px,3px;bos:solid;oX:50;oY:50;sp:0;"
                  >
                    {/* <div className="ls-btn-group">
            <a href="/contact" className="vs-btn">
              Contact Us
            </a>
          </div> */}
                  </rs-layer>
                  <rs-layer
                  data-pause-on-hover="false"
                    id="slider-2-slide-2-layer-7"
                    data-type="image"
                    data-rsp_ch="on"
                    data-xy="x:r;xo:87px,87px,87px,-136px;yo:234px,234px,234px,69px;"
                    data-text="l:22;a:inherit;"
                    data-dim="w:['36px','36px','36px','36px'];h:['284px','284px','284px','284px'];"
                    data-vbility="t,t,t,f"
                    data-frame_1="sR:10;"
                    data-frame_999="o:0;st:w;sR:9690;"
                    style={{ zIndex: "5" }}
                  >
                    <img
                      src="revolution/images/slider-2-part-02.png"
                      alt=""
                      className="tp-rs-img"
                      width="33"
                      height="260"
                      data-no-retina
                    />
                  </rs-layer>
                  <rs-layer
                    id="slider-2-slide-2-layer-8"
                    data-type="image"
                    data-rsp_ch="on"
                    data-xy="x:r;xo:-146px,-146px,-146px,-556px;yo:-2px,-2px,-2px,-165px;"
                    data-text="l:22;a:inherit;"
                    data-dim="w:['507px','507px','507px','507px'];h:['507px','507px','507px','507px'];"
                    data-vbility="t,t,t,f"
                    data-frame_1="sR:10;"
                    data-frame_999="o:0;st:w;sR:9690;"
                    data-loop_0="oX:50;oY:50;"
                    data-loop_999="rZ:360;sp:5000;yym:t;yys:t;yyf:t;"
                    style={{ zIndex: "9" }}
                  >
                    <img
                      src="revolution/images/windmill-last.png"
                      alt=""
                      className="tp-rs-img"
                      width="910"
                      height="910"
                      data-no-retina
                    />
                  </rs-layer>
                  <rs-layer
                    id="slider-2-slide-2-layer-12"
                    data-type="image"
                    data-rsp_ch="on"
                    data-xy="xo:0,0,0,183px;y:b;yo:1px,1px,1px,-51px;"
                    data-text="l:22;a:inherit;"
                    data-dim="w:['1854px','1854px','1854px','1854px'];h:['243px','243px','243px','243px'];"
                    data-basealign="slide"
                    data-frame_1="sR:10;"
                    data-frame_999="o:0;st:w;sR:9690;"
                    style={{ zIndex: "7" }}
                  >
                    <img
                      src="revolution/images/slider-3-part-02.png"
                      alt=""
                      className="tp-rs-img"
                      width="1854"
                      height="243"
                      data-no-retina
                    />
                  </rs-layer>
                  <rs-layer
                    id="slider-2-slide-2-layer-13"
                    data-type="image"
                    data-rsp_ch="on"
                    data-xy="x:r;xo:224px,224px,224px,11px;yo:169px,169px,169px,53px;"
                    data-text="l:22;a:inherit;"
                    data-dim="w:333px,333px,333px,188px;h:333px,333px,333px,188px;"
                    data-frame_1="sR:10;"
                    data-frame_999="o:0;st:w;sR:9690;"
                    data-loop_0="oX:50;oY:50;"
                    data-loop_999="rZ:360;sp:5000;yym:t;yys:t;yyf:t;"
                    style={{ zIndex: "8" }}
                  >
                    <img
                      src="revolution/images/windmill-last.png"
                      alt=""
                      className="tp-rs-img"
                      width="910"
                      height="910"
                      data-no-retina
                    />
                  </rs-layer>
                  <rs-layer
                    id="slider-2-slide-2-layer-14"
                    data-type="image"
                    data-rsp_ch="on"
                    data-xy="x:r;xo:372px,372px,372px,91px;yo:322px,322px,322px,135px;"
                    data-text="l:22;a:inherit;"
                    data-dim="w:33px,33px,33px,27px;h:261px,261px,261px,215px;"
                    data-frame_1="sR:10;"
                    data-frame_999="o:0;st:w;sR:9690;"
                    style={{ zIndex: "6" }}
                  >
                    <img
                      src="revolution/images/slider-2-part-01.png"
                      alt=""
                      className="tp-rs-img"
                      width="33"
                      height="261"
                      data-no-retina
                    />
                  </rs-layer>{" "}
                </rs-slide>
              </rs-slides>
            </rs-module>
          </rs-module-wrap>
          {/* <!-- END REVOLUTION SLIDER --> */}
        </div>
      </section>

  );
};

export default HeroSection;
