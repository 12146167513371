import React, { useState } from 'react';
import axios from 'axios'; // Import Axios
import { toast } from 'react-toastify';

const ContactForm = () => {
  const [submitMsg,setSubmitMsg] = useState("")
  const [loading, setLoading] = useState(false);

  

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        setLoading(true)
        const formData = new FormData(event.target);
      
        try {
            // Send form data through API using Axios
            const response = await axios.post('https://2023.stitchmyapp.com/Datta-infra/sendmail/contact.php', formData);
            // console.log('Form submitted successfully:', response.data);
            // console.log(response,"response.....")
            if(response.data == "Message has been sent"){
            setLoading(false)
            event.target.reset();

            toast.success("Thank you! Your message has been sent successfully.")
                // setSubmitMsg("Thanks your message has been sent succussfully ")
            }

            // Optionally, do something with the response, such as displaying a success message
        } catch (error) {
            console.error('Error submitting form:', error);
            setLoading(false)
            // Optionally, handle errors, such as displaying an error message to the user
        }
    };
  return (
<>
{/* <!--==============================
    Contact Form Area
    ==============================--> */}
    <section className=" space-top space-extra-bottom">
        <div className="container">
            <div className="row flex-row-reverse">
                <div className="col-lg-6 col-xl-5 pb-20 pb-lg-0 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="info-box">
                        <h3 className="info-box-title">Contact Information</h3>
                        <div>
                            <div className="vs-media">
                                <div className="media-icon"><img src="assets/img/icon/info-1-1.png" alt="info-icon"/></div>
                                <div className="media-body">
                                    <p className="media-info">Phone No: <br/> India <a href="tel:+911244488852" className="text-inherit">+91-124-448-8852</a></p>
                                </div>
                            </div>
                            <div className="vs-media">
                                <div className="media-icon"><i className="fas fa-map-marker-alt"></i></div>
                                <div className="media-body">
                                    <p className="media-info">Aipl Business Club, 12th floor, Gurugram, Haryana-122001, India</p>
                                </div>
                            </div>
                            <div className="vs-media">
                                <div className="media-icon"><i className="fas fa-envelope"></i></div>
                                <div className="media-body">
                                    <p className="media-info">Email Address: <br/> <a className="text-inherit" href="mailto:info@dattainfra.com">info@dattainfra.com</a></p>
                                </div>
                            </div>
                        </div>
                        <h3 className="info-box-title">Follow Us</h3>
                        <div className="info-social">
                            <a href="#"><i className="fab fa-facebook-f"></i></a>
                            <a href="#"><i className="fab fa-twitter"></i></a>
                            <a href="#"><i className="fab fa-linkedin-in"></i></a>
                            <a href="#"><i className="fab fa-google"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-xl-7 wow fadeInUp" data-wow-delay="0.4s">
                    <form className="form-style2 layout2 ajax-contact" onSubmit={handleSubmit}>
                        <h3 className="form-title">Have Question Feel Free to Contact Us?</h3>
                        <div className="row">
                            <div className="col-12 form-group">
                                <label htmlFor="name">Full Name</label>
                                <input required name="name" id="name" type="text" placeholder="Your Name"/>
                            </div>
                            <div className="col-md-6 form-group">
                                <label htmlFor="email">Email Address</label>
                                <input  required  name="email" id="email" type="email" placeholder="Example@domain.com"/>
                            </div>
                            <div className="col-md-6 form-group">
                                <label htmlFor="number">Contact No</label>
                                <input  required name="number" id="number" type="number" placeholder="Your number"/>
                            </div>
                            <div className="col-12 form-group">
                                <label htmlFor="message">Message</label>
                                <textarea required  name="message" id="message" placeholder="Your message"></textarea>
                            </div>
                            <div className="col-12 form-group">
                                <button type="submit" className="vs-btn">
                                {loading === true ? "Sending..." : "Send Message"}
                                </button>
                            </div>
                        </div>
                        {/* <p className="form-messages mb-0 mt-3"></p> */}
                        <p className=" mb-0 mt-3 text-blue">{submitMsg}</p>
                    </form>
                </div>
            </div>
        </div>
    </section>

</>
  )
}

export default ContactForm