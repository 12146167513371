import React, { useEffect } from "react";
import BreadCrum from "../../components/common/BreadCrum";
import Whoweare from "../../components/Whoweare";
import WhyChooseUs2 from "../../components/WhyChooseUs2";
import { useLocation } from "react-router-dom";
import { WhyChoose4 } from "../../components/WhyChoose4";

const WhoWeAre = () => {
  const { pathname } = useLocation();
  let slugName = pathname?.replace("/", "");
  slugName = slugName === "" || slugName === "index" ? "home" : slugName;
  const getData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/pages?filters[Slug][$eq]=${slugName}`
      );
      const result = await response.json();
      document.querySelector("#content").innerHTML =
        result?.data[0]?.attributes?.Content;
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <BreadCrum parenttitle={"About Us"} parent={"about"} title={"Who We Are"} child={"Who We Are"} slogan={"Driving Change, Powering Progress: Datta Infra's Legacy of Excellence in Renewable Infrastructure"}/>
      <div id="content"></div>
      {false && <div className="container">
        <div className="col-xl-9 m-auto">
          <span className="sec-subtitle text-center mt-4">
            Solar Harmony Powering the World Naturally.
          </span>
          <div
            className=" align-self-center text-center text-xl-center wow fadeInUp "
            data-wow-delay="0.5s"
          >
            <p className=" fs-md mb-xxl-4 pb-xl-2 mt-n1 justify">
              Datta power Infra founded by Varchasvi Gagal and has established
              itself as a prominent force within India&apos;s leading land
              aggregation and development sector. Our core mission revolves
              around providing an organized and sustainable solution for land
              consolidation and Transmission line, thereby strengthening the
              renewable energy landscape. With a current RE asset portfolio of
              2.5 GW, Our Civil work and Survey Testing, boosting our very own
              Ramming Machine for efficient commercial installation. Our
              methodology includes the utilization of TSS machines, Drone Survey
              Footage, Soil Testing and DGPS Technology. Notably, we have
              successfully achieved a significant milestone by successfully
              completion of 2 wind Mast of 150m height in Barmer, Rajasthan. We
              extend beyond land aggregation and development, offering a
              comprehensive suite of services that encompasses Land
              Infrastructure Development, Transmission Line EPC and SPV model
              based projects. Demonstrating an unwavering commitment to
              adherence and compliance, we skillfully navigate the intricate
              landscape of environmental and legal clearances, meticulously
              ensuring alignment with both State and Central Government policies
              and local regulations. Our operational footprint spans across
              Odisha, Chhattisgarh, Haryana, Madhya Pradesh, Uttar Pradesh,
              Tamil Nadu, Karnataka, Maharashtra and Rajasthan.
            </p>

            <p className=" fs-md mb-xxl-4 pb-xl-2 mt-n1 justify">
              With a current RE asset portfolio of 2.5 GW, Our Civil work and
              Survey Testing, boosting our very own Ramming Machine for
              efficient commercial installation. Our methodology includes the
              utilization of TSS machines, Drone Survey Footage, Soil Testing
              and DGPS Technology.{" "}
            </p>
          </div>
          <a href="/about" className="vs-btn ">
            Read More
          </a>
          <div style={{ position: "relative" }}>
            <video
              className="space-top space-bottom"
              loop
              muted
              autoPlay
              width="100%"
              height="500px"
            >
              <source src="assets/img/about/about-video.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <h4
              className="text-"
              style={{
                position: "absolute",
                top: "60%",
                left: "17%",
                color: "white",
              }}
            >
              We Provide Renewable Energy Services.
            </h4>
          </div>

          {/* <div className='position-absolute top-10  '>abcd</div> */}
        </div>
      </div>}
      {/* <Whoweare /> */}
      <WhyChoose4/>
      <WhyChooseUs2 />
    </>
  );
};

export default WhoWeAre;
