import React, { useEffect, useState } from "react";
import BreadCrum from "../components/common/BreadCrum";
import CareerForm from "../components/form/CareerForm";
import Whyjoindattainfra from "../components/whyjoindattainfra";
import { Workwithus } from "../components/workwithus";
import { Corevalue } from "../components/Corevalue";

const Career = () => {
  // const jobData = [
  //   {
  //     title: "Sr Manager/DGM",
  //     description: "Wind industry",
  //     requirements: ["Requirement 1", "Requirement 2", "Requirement 3"],
  //   },
  //   {
  //     title: "Software Engineer",
  //     description: "Technology Company",
  //     requirements: [
  //       "Experience with JavaScript",
  //       "Strong problem-solving skills",
  //       "Bachelor's degree in Computer Science",
  //     ],
  //   },
  //   {
  //     title: "Marketing Specialist",
  //     description: "E-commerce Company",
  //     requirements: [
  //       "Digital marketing experience",
  //       "Social media management skills",
  //       "Creative mindset",
  //     ],
  //   },
  //   {
  //     title: "Financial Analyst",
  //     description: "Finance Sector",
  //     requirements: [
  //       "Strong analytical skills",
  //       "Experience with financial modeling",
  //       "Bachelor's degree in Finance",
  //     ],
  //   },
  //   {
  //     title: "Customer Support Representative",
  //     description: "Service Industry",
  //     requirements: [
  //       "Excellent communication skills",
  //       "Problem-solving abilities",
  //       "Customer-focused mindset",
  //     ],
  //   },
  // ];
/*   const [data, setData] = useState([]);

  const getData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/careers`
      );
      const result = await response.json();
      // console.log("------>",result?.data);
      setData(result?.data);
    } catch (error) {
      console.log("Error:", error);
      setData([]);
    }
  };

  useEffect(() => {
    getData();
  }, []);
 */
  return (
    <>
      <BreadCrum
        title={"Careers"}
        slogan={"We Provide Renewable Energy Services."}
      />

      <Whyjoindattainfra/>
      <section className="space-extra-bottom">
        <Workwithus />
      </section>
 
      <Corevalue />
    </>
  );
};

export default Career;
