import React from 'react'
import BreadCrum from '../../components/common/BreadCrum'
import Footprint from '../../components/Footprint';
import Clients from '../../components/Clients';

const GlobalFootprint = () => {

    const applyHoverEffect = () => {
        // setCircleWidth('100%');
        // setButtonTextColor('white');
    
        // setTimeout(() => {
        //   setCircleWidth('21%');
        //   setButtonTextColor(textColor);
        // }, 3000);
      };
    
      const removeHoverEffect = () => {
        // setCircleWidth('21%');
        // setButtonTextColor(textColor);
      };
    

    
  return (
    <>
    <BreadCrum title={"Global Footprint"} />


<Footprint />
<Clients />
   
    </>

    
  )
}

export default GlobalFootprint