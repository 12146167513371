import React, { useEffect } from "react";
import BreadCrum from "../components/common/BreadCrum";
import Clients from "../components/Clients";
import WhyChooseUs2 from "../components/WhyChooseUs2";
import { useLocation } from "react-router-dom";

const OurClients = () => {
  const { pathname } = useLocation();
  let slugName = pathname?.replace("/", "");
  slugName = slugName === "" || slugName === "index" ? "home" : slugName;
  const getData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/pages?filters[Slug][$eq]=${slugName}`
      );
      const result = await response.json();
      // console.log("------>",result?.data[0]?.attributes?.Content);
      // document.querySelector("#content").innerHTML =
      //   result?.data[0]?.attributes?.Content;
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <BreadCrum title={"Our Clients"} slogan={"Innovating Sustainability: Datta Infra's Visionary Leadership Shapes India's Clean Energy Landscape"} />
      <div id="content"></div>
      <Clients />
      <WhyChooseUs2 />
    </>
  );
};

export default OurClients;
