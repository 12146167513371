import React, { useState } from "react";
import axios from "axios"; // Import Axios
import { toast } from "react-toastify";
import Newsletter from "../newsletter";

const ContactForm = () => {
  const [submitMsg, setSubmitMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    setLoading(true);
    const formData = new FormData(event.target);

    try {
      // Send form data through API using Axios
      const response = await axios.post(
        "https://2023.stitchmyapp.com/Datta-infra/sendmail/contact.php",
        formData
      );
      // console.log('Form submitted successfully:', response.data);
      // console.log(response,"response.....")
      if (response.data == "Message has been sent") {
        setLoading(false);
        event.target.reset();

        toast.success("Thank you! Your message has been sent successfully.");
        // setSubmitMsg("Thanks your message has been sent succussfully ")
      }

      // Optionally, do something with the response, such as displaying a success message
    } catch (error) {
      console.error("Error submitting form:", error);
      setLoading(false);
      // Optionally, handle errors, such as displaying an error message to the user
    }
  };
  return (
    <>
      {/* <!--==============================
    Contact Form Area
    ==============================--> */}
      <section className=" space-top space-extra-bottom faq-wrap1  ">
        <div className="container">
          <div className="row ">
            <div
              class="col-xl-5 mb-40 mb-xl-0 align-self-center wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <h2 class="sec-title h1 mb-4 pb-2 text-center text-xl-start">
                Faq’s
              </h2>
              <div class="accordion accordion-style1" id="faqVersion1">
                <div class="accordion-item">
                  <div class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      What are the key steps in developing a solar power
                      project?
                    </button>
                  </div>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqVersion1"
                  >
                    <div class="accordion-body">
                      The key steps include: <br />
                      <ul>
                        <li>Feasibility study and site assessment</li>
                        <li>Securing land and obtaining necessary permits</li>
                        <li>Designing the system and selecting equipment</li>
                        <li>Financing the project</li>
                        <li>Construction and installation</li>
                        <li>Commissioning and connecting to the grid</li>
                        <li>Operation and maintenance</li>
                      </ul>
                      <p></p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <div class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      How do I finance a solar power project?
                    </button>
                  </div>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#faqVersion1"
                  >
                    <div class="accordion-body">
                      <p>
                        Financing options include:
                        <br />
                        <ul>
                          <li> Equity investment </li>
                          <li>Debt financing through loans</li>
                          <li>Power purchase agreements (PPAs)</li>
                          <li>Government grants and incentives</li>
                          <li>Public-private partnerships</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <div class="accordion-header" id="headingThree">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      What permits and approvals are needed for a solar power
                      project?
                    </button>
                  </div>
                  <div
                    id="collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#faqVersion1"
                  >
                    <div class="accordion-body">
                      <p>
                        Required permits and approvals vary by location but
                        generally include:
                        <br />
                        <ul>
                          <li>Land use and zoning permits</li>
                          <li> Environmental impact assessments</li>
                          <li>Building and electrical permits</li>
                          <li>Grid connection agreements</li>
                          <li>Water usage permits (if applicable)</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <div class="accordion-header" id="headingFour">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      How do I estimate the cost of a solar power project?

                    </button>
                  </div>
                  <div
                    id="collapseFour"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#faqVersion1"
                  >
                    <div class="accordion-body">
                      <p>
                      Costs depend on factors such as system size, location, technology, and financing structure. Major cost components include:
           
                        <br />
                        <ul>
                          <li >Equipment <span>
                             (solar panels, inverters, mounting systems) </span></li>
                          <li>Installation and labour</li>
                          <li>Land acquisition</li>
                          <li>Permitting and legal fees</li>
                          <li>Operation and maintenance</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <div class="accordion-header" id="headingFive">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                            What are Power Purchase Agreements (PPAs)?

                    </button>
                  </div>
                  <div
                    id="collapseFive"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#faqVersion1"
                  >
                    <div class="accordion-body">
                      <p>
             

                      A PPA is a contract between a power producer and a purchaser (often a utility or large customer) that outlines the terms of energy sales, including pricing and duration.

                       
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 col-xl-7 wow fadeInUp"
              data-wow-delay="0.4s"
            >
              <form
                className="form-style2 layout2 layout3 ajax-contact"
                onSubmit={handleSubmit}
              >
                <h3 className="form-title">
                  Have Question Feel Free to Contact Us?
                </h3>
                <div className="row">
                  <div className="col-12 form-group">
                    <label htmlFor="name">Full Name</label>
                    <input
                      required
                      name="name"
                      id="name"
                      type="text"
                      placeholder="Your Name"
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label htmlFor="email">Email Address</label>
                    <input
                      required
                      name="email"
                      id="email"
                      type="email"
                      placeholder="Example@domain.com"
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label htmlFor="number">Contact No</label>
                    <input
                      required
                      name="number"
                      id="number"
                      type="number"
                      placeholder="Your number"
                    />
                  </div>
                  <div className="col-12 form-group">
                    <label htmlFor="message">Message</label>
                    <textarea
                      required
                      name="message"
                      id="message"
                      placeholder="Your message"
                    ></textarea>
                  </div>
                  <div className="col-12 form-group">
                    <button type="submit" className="vs-btn">
                      {loading === true ? "Sending..." : "Send Message"}
                    </button>
                  </div>
                </div>
                {/* <p className="form-messages mb-0 mt-3"></p> */}
                <p className=" mb-0 mt-3 text-blue">{submitMsg}</p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactForm;
