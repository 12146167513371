import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const Testimonial = () => {
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <section class=" space">
        <div className="title-area mb-3 pb-1 text-center">
          <span className="sec-subtitle">
            Transformative Excellence: Hear What Our Customers Have to Say
          </span>
          <h2 className="sec-title h1">Testimonial</h2>
        </div>

        <div class="testi-style2">
          <div class="shape-dotted jump-reverse"></div>
          <div id="">
            <Slider {...settings}>
              <div class="slide-1 holder">
                <div class="testi-body">
                  <div class="testi-img">
                    <img
                      src="assets/img/testimonial/testi-2-1.jpg"
                      alt="thumbnail"
                    />
                  </div>
                  <div class="media-body">
                    <div class="testi-rating">
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                    </div>
                    <p class="testi-text">
                      They were fantastic through the entire purchase process.
                      Had lots of ask and they were patient. When my system
                      arrived
                    </p>
                    <h3 class="testi-name h4">Martin Hope</h3>
                    <span class="testi-degi">PRO SYSTEMS FOUNDER</span>
                  </div>
                  <div class="quote-icon">
                    <img
                      src="assets/img/icon/quote-icon.png"
                      alt="quote icon"
                    />
                  </div>
                </div>
              </div>
              <div class="slide-1 holder">
                <div class="testi-body">
                  <div class="testi-img">
                    <img
                      src="assets/img/testimonial/testi-2-2.jpg"
                      alt="thumbnail"
                    />
                  </div>
                  <div class="media-body">
                    <div class="testi-rating">
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                    </div>
                    <p class="testi-text">
                      From its medieval origins to the digital era, learn
                      everything there is to know about the ubiquitous lorem
                      ipsum passage
                    </p>
                    <h3 class="testi-name h4">Jerzzy Lamot</h3>
                    <span class="testi-degi">VIP Member</span>
                  </div>
                  <div class="quote-icon">
                    <img
                      src="assets/img/icon/quote-icon.png"
                      alt="quote icon"
                    />
                  </div>
                </div>
              </div>
              <div class="slide-1 holder">
                <div class="testi-body">
                  <div class="testi-img">
                    <img
                      src="assets/img/testimonial/testi-2-3.jpg"
                      alt="thumbnail"
                    />
                  </div>
                  <div class="media-body">
                    <div class="testi-rating">
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                    </div>
                    <p class="testi-text">
                      The passage experienced a surge in popularity during the
                      1960s when Letraset used it on their dry-transfer sheets
                    </p>
                    <h3 class="testi-name h4">Mark Peter</h3>
                    <span class="testi-degi">SYSTEMS Developer</span>
                  </div>
                  <div class="quote-icon">
                    <img
                      src="assets/img/icon/quote-icon.png"
                      alt="quote icon"
                    />
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>
    </div>
  );
};
