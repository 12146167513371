import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Blog = () => {
  // const [blogs, setBlogs] = useState();
  const [blogsList, setBlogsList] = useState();
  const [iserror, setIsError] = useState(false);
  const navigate = useNavigate();

  const itemsPerPage = 4;
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const blogs = blogsList?.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASEURL}/api/blogs?populate=*`
        );
        const data = await response.json();

        // setBlogs(data.data);
        setBlogsList(data.data);

        setIsError(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsError(true);
      }
    };

    fetchData();
  }, []);

  const handleReadMore = (blogId) => {
    navigate("/blog-detail", { state: { id: `${blogId}` } });
  };

  return (
    <>
      <section className="vs-blog-wrapper blog-details space-top space-extra-bottom">
        <div className="container">
          <div className="row gx-40">
            <div className="col-lg-8">
              <div className="containe ">
                <div className="row gx-30 gy-3">
                  {blogs?.map((blog,i) => (
                      <div key={i}
                        className="col-xl-6 wow fadeInUp "
                        data-wow-delay="0.3s"
                        style={{ height: "550px", objectFit: "cover" }}
                      >
                        <div className="m-2">
                          <div className="vs-blog blog-style1 ">
                              <img
                                src={`${process.env.REACT_APP_BASEURL}${blog?.attributes.image.data.attributes.url}`}
                                alt="Blog Image"
                                style={{ height: "200px", objectFit: "cover" }}
                                className="w-100"
                              />
                            <h3 className="blog-title h-100 border   ">
                              <div
                                className="text-dark"
                                style={{ height: "60px", overflow: "hidden" }}
                              >
                                {blog?.attributes.title}
                              </div>
                            </h3>
                            <div className="blog-content">
                              <div className="blog-meta">
                                {/* <p className="blog-date">{blog.attributes.publishedAt}</p> */}

                                <p className="blog-date">{blog.attributes.date}</p>
                                {/* <a href="/blogs"><i className="fal fa-comment-lines"></i>21</a> */}
                              </div>
                              <p className="blog-text  ">
                                {blog.attributes.shortdescription}
                              </p>
                              <a
                                onClick={() => handleReadMore(blog.id)}
                                className="link-btn pointer"
                              >
                                Read More<i className="far fa-plus"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                  ))}

                  <ul className="vs-pagination pointer ">
                    <li>
                      <a
                        onClick={() => {
                          currentPage > 1 && setCurrentPage(currentPage - 1);
                        }}
                      >
                        <i className=" fas fa-chevron-left"></i>
                      </a>
                    </li>
                    {Array.from({
                      length: Math.ceil(blogsList?.length / itemsPerPage),
                    }).map((_, index) => (
                      <li key={index}>
                        <span
                          onClick={() => paginate(index + 1)}
                          className={currentPage === index + 1 ? "active" : ""}
                        >
                          {index + 1}
                        </span>
                      </li>
                    ))}
                    <li>
                      <a
                        onClick={() => {
                          currentPage < blogsList?.length / itemsPerPage &&
                            setCurrentPage(currentPage + 1);
                        }}
                      >
                        <i className="fas fa-chevron-right"></i>
                      </a>
                    </li>
                  </ul>
                  {/* {!iserror && (   <div className="vs-pagination  ">
                        <ul>
                            <li><a href="#"><i className="fas fa-chevron-left"></i></a></li>
                            <li><a href="#" className="active">1</a></li>
                            <li><a href="#">2</a></li>
                            <li><a href="#">3</a></li>
                            <li><a href="#"><i className="fas fa-chevron-right"></i></a></li>
                        </ul>
                    </div>)} */}
                </div>

                {iserror && (
                  <>
                    {" "}
                    <h3 className="text-center text-secondary">
                      {" "}
                      Ooops! blog isn't Available{" "}
                    </h3>{" "}
                  </>
                )}
              </div>

              {/* recent blog-------------------------------------_s */}
            </div>
            <div className="col-lg-4">
              <aside className="sidebar-area">
                {/* <div className="widget widget_search   ">
                            <h3 className="widget_title">Search</h3>
                            <form className="search-form">
                                <input type="text" placeholder="Search Here"/>
                                <button type="submit"><i className="far fa-search"></i></button>
                            </form>
                        </div> */}
                {/* <div className="widget widget_categories   ">
                            <h3 className="widget_title">About</h3>
                            <ul>
                                <li>
                                    <a href="/blogs">Solar Energy</a>
                                    <span>15</span>
                                </li>
                                <li>
                                    <a href="/blogs">Wind Energy</a>
                                    <span>11</span>
                                </li>
                                <li>
                                    <a href="/blogs">Hydropower</a>
                                    <span>55</span>
                                </li>
                                <li>
                                    <a href="/blogs">Battery Materials</a>
                                    <span>60</span>
                                </li>
                                <li>
                                    <a href="/blogs">Fossil Resources</a>
                                    <span>12</span>
                                </li>
                            </ul>
                        </div> */}
                <div className="widget  ">
                  <h3 className="widget_title">Recent Blogs</h3>
                  <div className="recent-post-wrap">
                    {blogsList?.slice(0, 4).map((blog) => (
                      <div className="recent-post" key={blog.id}>
                        <div className="media-img">
                          <a>
                            <img
                              src={`${process.env.REACT_APP_BASEURL}${blog.attributes.image.data.attributes.url}`}
                              alt="Blog Image"
                              className="rounded"
                              style={{
                                height: "80px",
                                width: "80px",
                                objectFit: "cover",
                              }}
                            />
                          </a>
                        </div>
                        <div className="media-body">
                          <div className="recent-post-meta">
                            <div className="fw-3 text-success">
                              {/* <i className="fal fa-calendar-alt"></i> */}
                              {blog.attributes.date}
                            </div>
                          </div>
                          <h4 className="post-title">
                            <a
                              className="text-inherit pointer"
                              onClick={() => handleReadMore(blog.id)}
                            >
                              {blog.attributes.title}
                            </a>
                          </h4>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog;
