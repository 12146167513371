import React from "react";
const Whyjoindattainfra = () => {
  return (
    <section class=" space-top space-extra-bottom">
      <div class="container">
        <div class="row">
          <div class="title-area text-center">
            <span class="sec-subtitle">Why you choose us</span>
            <h2 class="sec-title h1">Why Join Datta Infra ?</h2>
          </div>
          <p class="fs-md mb-5 pe-xxl-5 mt-n1">
            At Datta Infra, we are not just shaping the future of renewable
            energy — we&apos;re building careers that make a difference.
            Here&apos;s why you should consider joining our dynamic team:
          </p>
          <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
            <div class="pt-3"></div>
            <div class="img-box2">
              <div class="img-1">
                <img src="assets/img/about/why-choose-1.jpeg" alt="thumb" />
              </div>
            </div>
          </div>
          <div class="col-lg-6 wow fadeInUp pt-5" data-wow-delay="0.4s">
            <div class="" style={{ padding: "px" }}></div>

            <div class="vs-media about-media ">
              <div class="media-icon">
                <img
                  src="assets/img/icon/why-choose-1.png"
                  alt="feature icon"
                />
              </div>
              <div class="media-body">
                <span class="media-title h5">Impactful Work</span>
                <p class="media-info">
                  Be part of a company that is at the forefront of India&apos;s
                  renewable energy revolution. Every project you work on at
                  Datta Infra contributes to a sustainable future for
                  generations to come.
                </p>
              </div>
            </div>
            <div class="vs-media about-media ">
              <div class="media-icon">
                <img src="assets/img/icon/diagram.png" alt="feature icon" />
              </div>
              <div class="media-body">
                <span class="media-title h5">Professional Growth</span>
                <p class="media-info">
                  We believe in nurturing talent and providing opportunities for
                  career advancement. Whether you&apos;re an experienced
                  professional or just starting your career, Datta Infra offers
                  a supportive environment where you can learn, grow, and excel.
                </p>
              </div>
            </div>
            <div class="vs-media about-media ">
              <div class="media-icon">
                <img
                  src="assets/img/icon/scientific-innovation.png"
                  alt="feature icon"
                />
              </div>
              <div class="media-body">
                <span class="media-title h5">Innovative Culture</span>
                <p class="media-info">
                  Innovation is at the heart of everything we do. At Datta
                  Infra, you&apos;ll have the chance to collaborate with
                  like-minded individuals who are passionate about pushing
                  boundaries and finding creative solutions to complex
                  challenges.
                </p>
              </div>
            </div>
            <div class="vs-media about-media ">
              <div class="media-icon">
                <img src="assets/img/icon/commitment.png" alt="feature icon" />
              </div>
              <div class="media-body">
                <span class="media-title h5">Commitment to Excellence</span>
                <p class="media-info">
                  We take pride in our reputation for delivering projects of the
                  highest quality, on time and within budget. Joining Datta
                  Infra means joining a team that is committed to excellence in
                  everything we do.
                </p>
              </div>
            </div>
          </div>

          <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.4s">
            <div class="" style={{ padding: "px" }}></div>
            <div class="col-lg-6 wow fadeInUp nv2" data-wow-delay="0.3s">
              <div class="pt-3"></div>
              <div class="img-box2">
                <div class="img-1">
                  <img src="assets/img/about/output_image.jpeg" alt="thumb" />
                </div>
              </div>
            </div>
            <div class="vs-media about-media pt-5">
              <div class="media-icon">
                <img src="assets/img/icon/report.png" alt="feature icon" />
              </div>
              <div class="media-body pt-5">
                <span class="media-title h5">Positive Impact</span>
                <p class="media-info">
                  By joining Datta Infra, you&apos;ll not only advance your
                  career but also contribute to the greater good. Our work is
                  not just about building infrastructure — it&apos;s about
                  making a positive impact on the planet and the communities we
                  serve.
                </p>
              </div>
            </div>
            <div class="vs-media about-media ">
              <div class="media-icon">
                <img
                  src="assets/img/icon/collaboration.png"
                  alt="feature icon"
                />
              </div>
              <div class="media-body">
                <span class="media-title h5">Collaborative Environment</span>
                <p class="media-info">
                  We believe in the power of teamwork. At Datta Infra,
                  you&apos;ll collaborate with talented professionals from
                  diverse backgrounds, sharing ideas and expertise to achieve
                  common goals.
                </p>
              </div>
            </div>
            <div class="vs-media about-media ">
              <div class="media-icon">
                <img
                  src="assets/img/icon/why-choose-2.png"
                  alt="feature icon"
                />
              </div>
              <div class="media-body">
                <span class="media-title h5">Rewarding Benefits</span>
                <p class="media-info">
                  In addition to competitive salaries, Datta Infra offers a
                  range of benefits including health insurance, retirement
                  plans, and opportunities for professional development and
                  training.
                </p>
              </div>
            </div>
            <div class="vs-media about-media ">
              <div class="media-icon">
                <img
                  src="assets/img/icon/cultural-activities.png"
                  alt="feature icon"
                />
              </div>
              <div class="media-body">
                <span class="media-title h5">
                  Culture of Diversity and Inclusion
                </span>
                <p class="media-info">
                  We celebrate diversity and believe that a diverse workforce
                  fosters creativity, innovation, and growth. At Datta Infra,
                  everyone&apos;s voice is valued and respected.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 wow fadeInUp nv" data-wow-delay="0.3s">
            <div class="pt-3"></div>
            <div class="img-box2">
              <div class="img-1">
                <img src="assets/img/about/output_image.jpeg" alt="thumb" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Whyjoindattainfra;
