import React, { useEffect, useState } from "react";
import BreadCrum from "../components/common/BreadCrum";
import { useNavigate } from "react-router-dom";
import ProjectList from "../components/ProjectList";
import ProjectMap from "../components/ProjectMap";
import Footprint from "../components/Footprint";
import Select from "react-dropdown-select";
import { data } from "jquery";


const Projects = () => {
  const latitude = "28.5355";
  const longitude = "77.3910";


  const navigate = useNavigate();

  const [projects, setProjects] = useState();
  const [iserror, setIsError] = useState(false);
  const [location, setLocation] = useState();
  const [markerPositions, setMarkerPositions] = useState([])
  const [options, setOptions] = useState([])
  const [dropvalues, setdrop] = useState()
  const [dropindex, setindex] = useState()


  // const markerPositions = [
    // { lat: 21.8243, lng: 76.3504, info: "Khandwa" },
    // { lat: 20.9026, lng: 74.7749,  info: "Dhule" },
    // { lat: 16.789, lng: 75.4321,  info: "Koppal" },
    // { lat: 20.0059, lng: 73.7749,  info: "Nashik" },
    // { lat: 17.65992, lng: 75.906387, info: "Solapur" },
  // ];

  // setValues(values) 
  //   // Assuming this function stores the selected value somewhere
  //    console.log(values);
 
  const setValues = (values)=>{
    // Assuming this function stores the selected value somewhere
    // const selectedValue = values.id; // or whatever property holds the selected value
    setindex(values[0].value)
    setdrop(values[0].label)
   }

  useEffect(() => {
     const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASEURL}/api/projects?populate=*`
        );
        const data = await response.json();

        // Assuming your data structure is similar, adjust accordingly
        setIsError(false);
        setOptions(data.data.map((item, index) => ({
          value: index,
          label: item.attributes.name
        })));
        
        setProjects(data.data);
        const markersArray = [(data?.data)?.map((project,i)=>{
      
          return { lat: Number(project?.attributes?.Latitude), lng: Number(project?.attributes?.Longitude), info : project?.attributes?.LocationName }
               
              })]
        setMarkerPositions(...markersArray)
        setLocation(0)
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsError(true);

        // Handle the error as needed, for example, set an error state
      }
    };

    fetchData();
  }, []);

  
  
  // setOptions(...option,projects.map(data=> data.attributes.name))
  
  // useEffect(() => {
  //     fetch(`${process.env.REACT_APP_BASEURL}/api/projects?populate=*`)
  //              .then((res) => {
  //                  return res.json();
  //              })
  //              .then((res) => {
  //                console.log(res.data[0].attributes.image.data[0].attributes.url);
  //                setProjects(res.data);
  //              })
  //          }, []);

  const handleProjectDetail = (projectId) => {
    navigate("/project-detail", { state: { id: `${projectId}` } });
  };

  return (
    <>
      <BreadCrum title={"Our Projects"} parent={"projects"} slogan={"Datta Infra's Trailblazing Approach to Renewable Project Development"}/>

      <div className="container">
        <section className=" space-top space-extra-bottom">
          <div className="container">
            <div className="">
              <div className="title-area text-center">
                <h2 className="sec-title h1">Our Recent Projects </h2>
              </div>

              {/* {projects?.map(project=>( 
    <>






    <div className="col-xl-6 wow fadeInUp" data-wow-delay="0.3s"  >
                    <div className="project-style1 layout3">
                        <div className="project-img"><a ><img src={`${process.env.REACT_APP_BASEURL}${project.attributes.image.data.attributes.url}`} alt="project image" style={{height:"300px", objectFit:"cover"}}/></a></div>
                        <div className="project-content">
                            <div className="project-body pointer" onClick={()=>handleProjectDetail(project.id)}>
                                <span className="project-category">{project?.attributes.energytype}</span>
                                <h3 className="project-title2 h4"><a  className="text-inherit ">{project?.attributes.name}</a></h3>
                                <div className="project-energy">
                                    <span className="project-energytotal h4">{project?.attributes.watt}  <span className="quantity">{project?.attributes.powerunit} </span></span>
                                    <p className="project-energytext">of clean energy per year</p>
                                </div>
                            </div>
                         
                        </div>
                    </div>
                </div>


    </>
))
} */}
              {/* <ProjectMap latitude={latitude} longitude={longitude} /> */}
              <div className="container">
  <div className="row">
    <div className="col-md-6 ">
 <ProjectMap
          mPositions={markerPositions[location]}
          location={location}
          markePositions={markerPositions}
        />
    </div>
    <div className="col-md-6">


    <Select options={options} placeholder={"Select Project ..."}  onChange={(label) => setValues(label)} />
<div style={{marginTop:"10px"}}>
  {  markerPositions != [] &&  <ProjectList projects={projects} setLocation={setLocation} selectindex={dropindex} selecteddrop={dropvalues} />}
</div>
    </div>
  </div>
</div>

            </div>
            {iserror && (
              <>
                {" "}
                <h3 className="text-center text-secondary">
                  {" "}
                  Ooops! projects aren't Available{" "}
                </h3>{" "}
              </>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default Projects;
