import React, { useEffect, useState } from "react";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import CompanyOverview2 from "../components/CompanyOverview2";
import ProjectCompleted2 from "../components/ProjectCompleted2";
import FeatureArea from "../components/FeatureArea";
import WhyChooseUs2 from "../components/WhyChooseUs2";
import CaseStudies from "../components/CaseStudies";
import Clients from "../components/Clients";
import HeroCarousel from "../components/HeroCarousel";
import Footprint from "../components/Footprint";
import Gmap from "../components/Gmap";
import { useLocation } from "react-router-dom";
import HeroSection from "../components/HeroSection";
import OurFootprint from "../components/OurFootprint";
import ContactForm from "../components/form/ContactForm2";
import { Testimonial } from "../components/Testimonial";
import Newsletter from "../components/newsletter";

const Home = () => {
  const { pathname } = useLocation();
  let slugName = pathname?.replace("/", "");
  slugName = slugName === "" || slugName === "index" ? "home" : slugName;
  const getData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/pages?filters[Slug][$eq]=${slugName}`
      );
      const result = await response.json();
      // console.log("------>",result?.data[0]?.attributes?.Content);
      document.querySelector("#content").innerHTML =
        result?.data[0]?.attributes?.Content;
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <HeroSection />
      <section class="space-top space-extra-bottom">
        <div class="container">
          <div class="row text-center text-md-start">
            <div
              class="col-md-12 col-xl-6 align-self-center order-1 order-xl-1 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div class="mb-30">
                <span class="sec-subtitle">COMPANY OVERVIEW</span>
                <h2>Leaders in Clean Energy Solutions</h2>
                <p class="pe-xxl-5 fs-md mb-xxl-4 pb-xl-2 mt-n1 justify">
                  Datta Infra founded by Varchasvi Gagal and has established
                  itself as a prominent force within Indias leading land
                  aggregation and development sector. Our core mission revolves
                  around providing an organized and sustainable solution for
                  land consolidation and Transmission line, thereby
                  strengthening the renewable energy landscape.
                </p>

                <p class="pe-xxl-5 fs-md mb-xxl-4 pb-xl-2 mt-n1 justify">
                  With a current RE asset portfolio of 2.5 GW, Our Civil work
                  and Survey Testing, boosting our very own Ramming Machine for
                  efficient commercial installation. Our methodology includes
                  the utilization of TSS machines, Drone Survey Footage, Soil
                  Testing and DGPS Technology.{" "}
                </p>
                <a href="/about" class="vs-btn">
                  About Us
                </a>
              </div>
            </div>

            <div
              class="  col-xl-6 over  wow fadeInUp order-1 order-xl-1 my-3 my-md-0"
              data-wow-delay="0.4s"
            >
              <span class="sec-subtitle">STRONG STRENGTH</span>

              <div class="counter-style1">
                <p class="counter-number h1">5+</p>
                <p class="counter-text">
                  Wind energy Projects in <br />
                  Barmer, Rajasthan
                </p>
              </div>
              <div class="counter-style1">
                <span class="counter-number h1">50+</span>
                <p class="counter-text">
                  Projects Completion <br /> in India
                </p>
              </div>
              <div class="counter-style1">
                <span class="counter-number h1">55,000+</span>
                <p class="counter-text">
                  Acres of land acquired <br /> for solar projects
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="content"></div>
      <WhyChooseUs2 />
      {/* <Footprint /> */}
      <OurFootprint />
      {/*        <Testimonial/>
       */}{" "}
      <Clients />
      <ContactForm />
      <Newsletter />
    </>
  );
};

export default Home;
