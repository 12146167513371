import React from "react";

const Clients = () => {
  return (
    <>
      <section className=" space-bottom space-top">
        <div className="container">
          <div className="row rowext justify-content-center">
            <div
              className="col-lg-6 col-xl-5 col-xxl-4 align-self-center mb-40 mb-xl-0 text-center text-xl-start wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <span className="sec-subtitle">CLIENTS</span>
              <h2 className="sec-title mb-n2">
                Building Strong, Long Lasting Relationships
              </h2>
            </div>
            <div
              className="col-xl-7 offset-xxl-1 z-index-common wow fadeInUp"
              data-wow-delay="0.4s"
            >
              <div className="brand-wrap1">
                {/* <div className="shape-dotted jump"></div> */}
                <div className="row  gx-0">
                  <div className="col-md-6 col-lg-3 dash-col-3 dash-md-6">
                    <div className="brand-style1">
                      <img src="assets/img/brand/brand-1-2.png" alt="brand" />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3 dash-col-3 dash-md-6">
                    <div className="brand-style1">
                      <img src="assets/img/brand/brand-1-3.png" alt="brand" />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3 dash-col-3 dash-md-6">
                    <div className="brand-style1">
                      <img src="assets/img/brand/brand-1-4.png" alt="brand" />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3 dash-col-3 dash-md-6">
                    <div className="brand-style1">
                      <img src="assets/img/brand/brand-1-7.png" alt="brand" />
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3 dash-col-3 dash-md-6">
                    <div className="brand-style1">
                      <img src="assets/img/brand/brand-1-8.png" alt="brand" />
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3 dash-col-3 dash-md-6">
                    <div className="brand-style1">
                      <img src="assets/img/brand/brand-1-0.png" alt="brand" />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3 dash-col-3 dash-md-6">
                  <div className="brand-style1">
                    <img src="assets/img/brand/brand-1-10.png" alt="brand" />
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Clients;
