import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom';
import $ from "jquery"

const Header = () => {
    const [currentPath, setCurrentPath] = useState("")
    const [isMenuSlider, setIsMenuSlider] = useState("false")
    const menuAreaRef = useRef(null);
    function handleMouseOut(event) {
            setIsMenuSlider("false");
    }
    const [isInfoVisible, setInfoVisible] = useState(false);
    let hideTimeout = null;

    const handleMouseEnter = () => {
      if (hideTimeout) {
        clearTimeout(hideTimeout);
        hideTimeout = null;
      }
      setInfoVisible(true);
    };
  
    const handleMouseLeave = () => {
      hideTimeout = setTimeout(() => {
        setInfoVisible(false);
      }, 1000);
    };
  

    React.useEffect(() => {
        // document.addEventListener('mouseout', handleMouseOut);
        return () => {
            // document.removeEventListener('mouseout', handleMouseOut);
        };
    }, []);

    useEffect(() => {
        // Get the current path using window.location.pathname
        setCurrentPath(window.location.pathname)
    
        // Do something with the current path

        
    
        // You can add any logic based on the current path here
    
        // Make sure to clean up any resources or subscriptions if needed
        return () => {
          // Cleanup logic goes here if necessary
        };
      }, []);



    useEffect(()=>{
        return ()=>{
            (function ($) {
            //   "use strict";
            $(window).on('scroll',function(){
                if($(window).scrollTop()){
                    $('nav').addClass('active');
                }
                // else{
                //     $('nav').removeClass('active');
                // }
            })
            
              /*---------- 03. Mobile Menu Active ----------*/
              $.fn.vsmobilemenu = function (options) {
                var opt = $.extend({
                  menuToggleBtn: '.vs-menu-toggle',
                  bodyToggleClass: 'vs-body-visible',
                  subMenuClass: 'vs-submenu',
                  subMenuParent: 'vs-item-has-children',
                  subMenuParentToggle: 'vs-active',
                  meanExpandClass: 'vs-mean-expand',
                  appendElement: '<span class="vs-mean-expand"></span>',
                  subMenuToggleClass: 'vs-open',
                  toggleSpeed: 400,
                }, options);
            
                return this.each(function () {
                  var menu = $(this); // Select menu
            
                  // Menu Show & Hide
                  function menuToggle() {
                    menu.toggleClass(opt.bodyToggleClass);
            
                    // collapse submenu on menu hide or show
                    var subMenu = '.' + opt.subMenuClass;
                    $(subMenu).each(function () {
                      if ($(this).hasClass(opt.subMenuToggleClass)) {
                        $(this).removeClass(opt.subMenuToggleClass);
                        $(this).css('display', 'none')
                        $(this).parent().removeClass(opt.subMenuParentToggle);
                      };
                    });
                  };
            
                  // Class Set Up for every submenu
                  menu.find('li').each(function () {
                    var submenu = $(this).find('ul');
                    submenu.addClass(opt.subMenuClass);
                    submenu.css('display', 'none');
                    submenu.parent().addClass(opt.subMenuParent);
                    submenu.prev('a').append(opt.appendElement);
                    submenu.next('a').append(opt.appendElement);
                  });
            
                  // Toggle Submenu
                  function toggleDropDown($element) {
                    if ($($element).next('ul').length > 0) {
                      $($element).parent().toggleClass(opt.subMenuParentToggle);
                      $($element).next('ul').slideToggle(opt.toggleSpeed);
                      $($element).next('ul').toggleClass(opt.subMenuToggleClass);
                    } else if ($($element).prev('ul').length > 0) {
                      $($element).parent().toggleClass(opt.subMenuParentToggle);
                      $($element).prev('ul').slideToggle(opt.toggleSpeed);
                      $($element).prev('ul').toggleClass(opt.subMenuToggleClass);
                    };
                  };
            
                  // Submenu toggle Button
                  var expandToggler = '.' + opt.meanExpandClass;
                  $(expandToggler).each(function () {
                    $(this).on('click', function (e) {
                      e.preventDefault();
                      toggleDropDown($(this).parent());
                    });
                  });
            
                  // Menu Show & Hide On Toggle Btn click
                  $(opt.menuToggleBtn).each(function () {
                    $(this).on('click', function () {
                      menuToggle();
                    })
                  })
            
                  // Hide Menu On out side click
                  menu.on('click', function (e) {
                    e.stopPropagation();
                    menuToggle()
                  })
            
                  // Stop Hide full menu on menu click
                  menu.find('div').on('click', function (e) {
                    e.stopPropagation();
                  });
            
                });
              };
            
              $('.vs-menu-wrapper').vsmobilemenu();
            
            


              
            })(jQuery);
        }
    },[])
    

   

  return (
    <>
    
    <div>
    {/* <!--==============================
    Mobile Menu
  ============================== --> */}
    <div className={`vs-menu-wrapper ${isMenuSlider=="true" ? 'vs-body-visible' : ''}`}  >
        <div className="vs-menu-area text-center"    ref={menuAreaRef}   >
            <div  >

            <button  
            className="vs-menu-toggle"  
                             onClick={()=>{  setIsMenuSlider("false")}}
                             >
                                        <i className="fal fa-times"></i>
                                     </button>
                                        </div>
            <div className="mobile-logo">
                <a href="/"><img src="assets/img/logo-black.png" 
                style={{maxHeight: "100px"}}
                 alt="DATTA INFRA"/></a>
                 {/* {isMenuSlider} */}
            </div>
            <div className="vs-mobile-menu">
                <ul>
                    <li>
                        <a href="/">Home  </a>
                    </li>
                    <li className="menu-item-has-children">
                        <a href="/about">About Us</a>
						<ul className="sub-menu">
                            <li><a href="/who-we-are">Who We Are</a></li>
                            <li><a href="/core-purpose">Core Purpose and Values</a></li>
                            <li><a href="/leadership-team">Leadership Team</a></li>
							<li><a href="/download">Download</a></li>
							{/* <li><a href="/news">News</a></li> */}
							{/* <li><a href="/global-footprint">Global Footprints</a></li> */}
							<li><a href="/our-journey">Our Journey</a></li>
						

                            
                       </ul>
                    </li>
                    <li>
                       <a href="/services">Services</a>
                    </li>
					<li>
                        <a href="/our-clients">Clients</a>
                    </li>
                    {/* <li>
                        <a href="/blogs">Blog</a>
                    </li> */}
                    <li><a href="/projects">Projects</a></li>
                    <li><a href="/news-media">News & Media</a></li>
                    <li><a href="/careers">Careers</a></li>

{/* 
                    <li className="menu-item-has-children">
                                        <a href="">More</a>
										<ul className="sub-menu">
                                            
                                            <li><a href="/media">Media</a></li>
										
				                 		

                                        </ul>
                                    </li> */}

                </ul>
            </div>
        </div>
    </div>
   

    {/* <!--==============================
    Sidemenu
============================== --> */}
    <div className="sidemenu-wrapper d-none d-lg-block">
        <div className="sidemenu-content">
            <button className="closeButton sideMenuCls"><i className="far fa-times"></i></button>
            <div className="widget  ">
                <div className="vs-widget-about">
                    <div className="footer-logo">
                        <a href="/"><img src="assets/img/logo.png"
                         style={{maxHeight: "90px"}}
                         alt="DATTA INFRA" /></a>

                    </div>
                    <p className="footer-text">Datta Infra is one of the emerging Renewable Energy developer players in India catering to the country’s top renewable corporates. It was founded in 1987 as an accounting and legal consulting firm.</p>
                    <div className="info-social ">
                        <a href="#" className='bg-dark'><i className="fab fa-facebook-f"></i></a>
                        <a href="#" className='bg-dark' ><i className="fab fa-twitter"></i></a>
                        <a href="https://www.linkedin.com/company/datta-power-infra" className='bg-dark' target='_blank'><i className="fab fa-linkedin-in"></i></a>
                        <a href="#" className='bg-dark'><i className="fab fa-instagram"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!--==============================
    Header Area
    ==============================--> */}


<header className="vs-header header-layout2">
    <div className="header-top">
        <div className="container">
            <div className="row justify-content-between align-items-center">
                <div className="col d-none d-lg-block">
                    {/* <p className="header-top-text"><span className="shape"></span>Datta Power Infra Private Limited.</p> */}
                </div>
                <div className="col-auto col-lg-2">
                <div 
                className="header-social"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <a href="https://www.linkedin.com/company/datta-power-infra" target='_blank' rel="noopener noreferrer">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
              
              <div className={`info-header ${isInfoVisible ? 'visible' : ''}`}     onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}>
                <a href="https://www.linkedin.com/company/datta-power-infra" target='_blank' rel="noopener noreferrer">
                  <i className="fab fa-linkedin-in" style={{ fontSize: 'larger' }}></i> - Datta Infra Power
                </a>
                <div>
                  <a href="tel:+911244488852">+91-124-448-8852</a>
                </div>
                <div>
                  <a href='mailto:info@dattainfra.com'>info@dattainfra.com</a>
                </div>
              </div>
                </div>
                <div className="col-auto ms-auto">
                    {/* Display visitors count */}
                    <p className="header-top-text">
                        Visits:{" "}
                        <img width="60px" src="https://hitwebcounter.com/counter/counter.php?page=14366749&style=0025&nbdigits=5&type=page&initCount=21031" title="Counter Widget" Alt="Visit counter For Websites"   border="0" />    
                         +
                    </p>
                </div>
                <div className="col-auto">
                    {/* <a href="/contact" className="header-top-text"><i className="fas fa-map-marker-alt"></i>Find A Location</a> */}
                </div>
            </div>
        </div>
    </div>
    <div className="sticky-wrapper">
        <div className="sticky-active">
            <div className="menu-area">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-auto">
                            <div className="vs-logo">
                                <a href="/"><img src="assets/img/logo-white.png" alt="DATTA INFRA" /></a>
                                {/* {isMenuSlider} */}
                            </div>
                        </div>
                        <div className="col text-end text-xl-center">
                            <nav className="main-menu menu-hover1 menu-style3 d-none d-lg-block">
                                <ul>
                                    <li>
                                        <a href="/" className={currentPath == "/" ? "active-menu" : ""}>Home</a>
                                    </li>
                                    <li className="menu-item-has-children">
                                        <a href="/about" className={currentPath == "/about" ? "active-menu" : ""}>About Us</a>
                                        <ul className="sub-menu">
                                            <li><a href="/who-we-are">Who We Are</a></li>
                                            <li><a href="/core-purpose">Core Purpose and Values</a></li>
                                            <li><a href="/leadership-team">Leadership Team</a></li>
                                            <li><a href="/download">Download</a></li>
                                            {/* <li><a href="/news">News</a></li> */}
                                            {/* <li><a href="/global-footprint">Global Footprints</a></li> */}
                                            <li><a href="/our-journey">Our Journey</a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="/services" className={currentPath == "/services" ? "active-menu" : ""}>Services</a>
                                    </li>
                                    <li>
                                        <a href="/our-clients" className={currentPath == "/our-clients" ? "active-menu" : ""}>Clients</a>
                                    </li>
                                    {/* <li>
                                        <a href="/blogs" className={currentPath == "/blogs" ? "active-menu" : ""}>Blog</a>
                                    </li> */}
                                    <li><a href="/projects" className={currentPath == "/projects" ? "active-menu" : ""}>Projects</a></li>
                                    <li><a href="/news-media" className={currentPath == "/news-media" ? "active-menu" : ""}>News & Media</a></li>
                                    <li><a href="/careers" className={currentPath == "/careers" ? "active-menu" : ""}>Careers</a></li>
                                    {/* <li className="menu-item-has-children">
                                        <a href="">More</a>
                                        <ul className="sub-menu">
                                            <li><a href="/careers">Careers</a></li>
                                            <li><a href="/news">News </a></li>
                                            <li><a href="/media">Media</a></li>
                                        </ul>
                                    </li> */}
                                </ul>
                            </nav>
                            <button className="vs-menu-toggle d-inline-block d-lg-none"><i className="fal fa-bars" onClick={() => { setIsMenuSlider("true") }}></i></button>
                        </div>
                        <div className="col-auto d-none d-xl-block">
                            <div className="header-btns">
                                <button className="bar-btn style2 sideMenuToggler">
                                    <span className="bar"></span>
                                    <span className="bar"></span>
                                    <span className="bar"></span>
                                </button>
                                {/* <a href="/contact" className="vs-btn">Contact us</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>


    </div>
    
    
    </>
  )
}

export default Header