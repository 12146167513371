import React from 'react'

const Footer = () => {
  return (
    <>
        {/* <!--==============================
			Footer Area
	==============================--> */}
	<footer className="footer-wrapper footer-layout1" 
     style={{ backgroundImage: 'url(/assets/img/footer-bg-1-1.jpg)' , backgroundRepeat:"no-repeat" }}
    // data-bg-src="assets/img/bg/footer-bg-1-1.jpg" 
    data-overlay="custom1" data-opacity="5">

        <div className="widget-area">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-md-6 col-lg-4">
                        <div className="widget footer-widget">
                            <div className="vs-widget-about">
                                <div className="footer-logo">
                                    <a href="/"><img src="assets/img/logo-white.png" alt="Datta Infra" 
                                    style={{height: "120px"}}
                                    /></a>
                                </div>
                                <p className="footer-text">Datta Infra is one of the emerging Renewable Energy developer players in India catering to the country’s top renewable corporates.</p>
								<div className="info-social style2">
									<a href="#"><i className="fab fa-facebook-f"></i></a>
									<a href="#"><i className="fab fa-twitter"></i></a>
									<a href="https://www.linkedin.com/company/datta-power-infra" target='_blank'><i className="fab fa-linkedin-in"></i></a>
									<a href="#"><i className="fab fa-instagram"></i></a>
								</div>

                                <div className="element2 text-center">
									<img src="assets/img/gif1.gif" alt="Renewable Energy" />
								</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <div className="widget widget_nav_menu  footer-widget">
                            <h3 className="widget_title" style={{color:"white"}}>Useful Links</h3>
                            <div className="menu-all-pages-container footer-menu">
                                <ul className="menu">
                                    <li><a href="/">Home</a></li>
									<li><a href="/blogs">Blog</a></li>
                                    <li><a href="/about">About Us</a></li>
									{/* <li><a href="privacy-policy">Privacy Policy</a></li> */}
                                    <li><a href="/services">Services</a></li>
									{/* <li><a href="#">Terms & Conditions</a></li> */}
                                    <li><a href="/our-clients">Clients</a></li>
                                    <li><a href="/contact">Contact</a></li>
                                    <li><a href="/careers">Careers</a></li>
                                </ul>
                            </div>
                        </div>
                            <div className="element3 text-center">
							<img src="assets/img/gif1.gif" alt="Renewable Energy"/>
						</div>
                    </div>
                    <div className="col-md-12 col-lg-4">
                        <div className="contact-style1">
                        <h3 className="contact-title h6">Headquarter</h3>
                        <p className="contact-text">Aipl Business Club, 12th floor, Gurugram, Haryana-122001, India</p>
                        <div className="contact-info">
                            <img src="assets/img/icon/info-1-2.png" alt="icon"/>
                            <a href="tel:+911244488852">+91-124-448-8852</a>
                        </div>
                        <div className="contact-info">
                            <i className="fas fa-envelope"></i>
                            <a href="mailto:info@dattainfra.com">info@dattainfra.com</a>
                        </div>
                    </div>
                    <div className="element1 text-center">
							<img src="assets/img/gif1.gif" alt="Renewable Energy" />
						</div>
                    </div>
                </div>
            </div>
        </div>
        <div className="copyright-wrap">
            <div className="container">
                <div className="row justify-content-between align-items-center">
                    <div className="text-center col-lg-auto">
                        <p className="copyright-text">Copyright <i className="fal fa-copyright"></i> 2024 <a className="text-white" href="/">Datta Infra</a>. All rights reserved.</p>
                    </div>
                    <div className="col-auto d-none d-lg-block">
                        <div className="copyright-menu">
                            <ul className="list-unstyled">                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>

    
    </>
  )
}

export default Footer