import React, { useEffect } from "react";
import BreadCrum from "../../components/common/BreadCrum";
import Whoweare from "../../components/Whoweare";
import Leadership from "./Leadership";
import ProjectCompleted2 from "../../components/ProjectCompleted2";
import { useLocation } from "react-router-dom";
import WhyChooseUs2 from "../../components/WhyChooseUs2";
import Whychoose3 from "../../components/whychoose3";

// import WhoWeAre from './WhoWeAre'

const CompanyProfile = () => {
  const { pathname } = useLocation();
  let slugName = pathname?.replace("/", "");
  slugName = slugName === "" || slugName === "index" ? "home" : slugName;
  const getData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/pages?filters[Slug][$eq]=${slugName}`
      );
      const result = await response.json();
      document.querySelector("#content").innerHTML =
        result?.data[0]?.attributes?.Content;
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <BreadCrum title={"Our Journey"} parenttitle={"About Us"} parent={"about"} child={"Our Journey"} slogan={"Empowering India's Future: Datta Infra Delivers Unmatched Renewable Solutions and Impact"}/>
      <div className="space-top ">
      <Whychoose3/>

      <div id="content"></div>
      </div>
      {/* <Whoweare />
      <ProjectCompleted2 /> */}
    </>
  );
};

export default CompanyProfile;
