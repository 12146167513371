import React,{useEffect,useState} from 'react'
import BreadCrum from './BreadCrum';
import { useLocation } from 'react-router-dom';

const BlogDetail = () => {

    const location = useLocation()
    const [blog,setBlog] = useState()
    const { id } = location.state;
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BASEURL}/api/blogs/${id}?populate=*`);
                
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
    
                const data = await response.json();
                setBlog(data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
                // You can handle the error here, e.g., show a message to the user
            }
        };
    
        fetchData();
    }, []);
    
  return (
   <>


<BreadCrum  title={`${blog?.attributes.title}`}   parenttitle={"Blogs"} parent={"blogs"} child={`${blog?.attributes.title}`} slogan={"Building Tomorrow's World: Datta Infra's Dedication to Renewable Energy Excellence"}/>
   



<section className="vs-blog-wrapper blog-details space-top space-extra-bottom">
        <div className="container">
            <div className="row gx-40">
                <div className="col-lg-12">
                
        <h2 className="sec-title mb-n2 col-11 m-auto ">{blog?.attributes.title}</h2>
        <div className="containe ">

       
   
        <div className="row gx-30 gy-3">

<div className="col-xl-12 wow fadeInUp" data-wow-delay="0.3s"  >
     <div className="vs-blog blog-style1">
         <div className="blog-img">
             <img src={`${process.env.REACT_APP_BASEURL}${blog?.attributes?.image.data.attributes.url}`} alt="Blog Image" style={{height:"400px", objectFit:"cover"}} className="w-100"/>
         </div>
         <h3 className="blog-title"><a >{blog?.attributes.title}</a></h3>
         <div className="blog-content">
             <div className="blog-meta">
                 <div  className="blog-date">{blog?.attributes.date}</div>
             </div>
             <p className="blog-text">{blog?.attributes.description}</p>
         </div>
     </div>
 </div>
 </div>

</div>

{/* recent blog-------------------------------------_s */}
                </div>
       
            </div>
        </div>
    </section>
   </>
  )
}

export default BlogDetail