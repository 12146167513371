// SignupForm.js
import React, { useState } from "react";
import { toast } from 'react-toastify';

const Newsletter = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission, e.g., send email to your server
    console.log("Email submitted: ", email);
    setEmail("");
    toast.success("Congratulations! You're now subscribed to our newsletter.")

  };

  return (

    <section class="overflow-hidden newsletter-op" data-overlay="custom1" data-opacity2="5" style={{background: "url('assets/img/bg/subsc-2-1.jpg')"}}>
    <div class="container">
        <div class="row gx-0 text-center text-lg-start">
            <div class="col-lg col-xl-7 ">
                <form class="form-style1" onSubmit={handleSubmit} >
                    <h2 class="form-title" style={{color: "white"}}>Subscribe to our newsletter.</h2>
                    <div class="form-group sletter">
                        <input type="email"       
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email address...." required />
                        <button class="vs-btn" type="submit">Subscribe</button>
                    </div>
                </form>
            </div>
            <div class="col-lg-auto col-xl">
                <div class="social-box2 ">
                    <h3 class="social-box__title" style={{color: "white"}}>Get in Touch</h3>
                    <a href="#"><i class="fab fa-facebook-f" style={{color:"var(--theme-color2)"}}></i></a>
                    <a href="https://www.linkedin.com/company/datta-power-infra"><i class="fab fa-twitter" style={{color:"var(--theme-color2)"}}></i></a>
                    <a href="#"><i class="fab fa-linkedin-in" style={{color:"var(--theme-color2)"}}></i></a>
                    <a href="#"><i class="fab fa-google" style={{color:"var(--theme-color2)"}}></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
 
  );
};

export default Newsletter;
