import React, { useEffect } from "react";
import pinImg from "../pin.png";
import { Loader } from "@googlemaps/js-api-loader";

const ProjectMap = ({ mPositions, location ,markePositions}) => {

console.log( mPositions, location ,markePositions);
  

  
  const markerPositions = [
    mPositions,
    // { lat: 21.8243, lng: 76.3504, color: 'orange', info: 'Khandwa' },
  ];
  // const markerPositions = [mPositions]
  const loader = new Loader({
    apiKey: "AIzaSyAHfpaBgpYA1LeUKJ02GYNEvBbNJNy9-BA",
    version: "weekly",
    libraries: ["places"],
  });
  const centerCoordinates = { lat: mPositions ? mPositions.lat :19.846811 , lng: mPositions? mPositions.lng:75.890633 };

  
  const mapStyles= [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dadada"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#c9c9c9"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    }
  ]
  const mapOptions = {
    zoom: 6,
    center: centerCoordinates,
    styles: mapStyles,
  };
  useEffect(() => {
    loader
      .load()
      .then((google) => {
        const map = new google.maps.Map(
          document.getElementById("map"),
          mapOptions
        );
        const infoWindow = new google.maps.InfoWindow({
          disableAutoPan: true,
        });

        const icon = {
          url: "/assets/img/security-pin_6125244.png",
          scaledSize: new google.maps.Size(25, 25),
        };
        markerPositions?.forEach((position, index) => {
          let marker = new google.maps.Marker({
            position: position,
            map: map,
            title: `Marker ${index + 1}`,
            icon: icon,
          });

          marker.setMap(map);
          infoWindow.setContent(position?.info);
          infoWindow.open(map, marker);
        });
      })
      .catch((e) => {
        // do something
        console.error("Map Error:", e);
      });
  }, [location]);

  return (
    // Map container
    <div
      className="container"
      id="map"
      style={{
        // marginTop: "30px",
        marginBottom: "80px",
        height: "400px",
        width: "100%",
      }}
    ></div>
  );
};

export default ProjectMap;
