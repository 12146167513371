import React,{useEffect,useState} from 'react'
import BreadCrum from './BreadCrum';
import { useLocation } from 'react-router-dom';

const NewsDetail = () => {

    const location = useLocation()
    const [news,setNews] = useState()
    const { id } = location.state;
    useEffect(()=>{

        const fetchNews = async () =>{
                
            try{
                const response = await fetch(`${process.env.REACT_APP_BASEURL}/api/news/${id}?populate=*`);
                const data = await response.json();

                setNews(data.data)
            } catch (error){
                console.log("Error;", error);
            }

        }
        fetchNews()

    },[])

    
  return (
   <>


<BreadCrum title={"News & Media"} parent={"news-media"} child={`${news?.attributes.title}`}/>
   



<section className="vs-blog-wrapper blog-details space-top space-extra-bottom">
        <div className="container">
            <div className="row gx-40">
                <div className="col-lg-12">
                
        <h2 className="sec-title mb-n2 col-11 m-auto ">{news?.attributes.title}</h2>
        <div className="containe ">

       
   
        <div className="row gx-30 gy-3">

<div className="col-xl-12 wow fadeInUp" data-wow-delay="0.3s"  >
     <div className="vs-blog blog-style1">
         <div className="">
             <img src={`${process.env.REACT_APP_BASEURL}${news?.attributes?.image.data.attributes.url}`} alt="Blog Image" style={{height:"400px", objectFit:"cover"}} className="w-100"/>
         </div>
         <h3 className="blog-title"><a >{news?.attributes.title}</a></h3>
         <div className="blog-content">
             <div className="blog-meta">
                 {/* <a  className="blog-date">January 05, 2022</a> */}
             </div>
             <p className="blog-text">{news?.attributes.description}</p>
         </div>
     </div>
 </div>
 </div>

</div>

{/* recent blog-------------------------------------_s */}
                </div>
       
            </div>
        </div>
    </section>
   </>
  )
}

export default NewsDetail