import React, { useEffect, useState } from "react";
import BreadCrum from "../components/common/BreadCrum";
import { WhatWeOffer } from "../components/WhatWeOffer";
import ProjectsCompleted1 from "../components/ProjectsCompleted1";
import WhyChooseUs2 from "../components/WhyChooseUs2";
import WorkFlow from "../components/WorkFlow";
import { useLocation } from "react-router-dom";
import { Howwework } from "../components/Howwework";

const Services = () => {
  const { pathname } = useLocation();
  let slugName = pathname?.replace("/", "");
  slugName = slugName === "" || slugName === "index" ? "home" : slugName;
  const getData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/pages?filters[Slug][$eq]=${slugName}`
      );
      const result = await response.json();
      document.querySelector("#content").innerHTML =
        result?.data[0]?.attributes?.Content;
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <BreadCrum
        title={"Our Services"}
        slogan={
          "Transforming Futures: Datta Infra Pioneers Renewable Energy Solutions with Proven Excellence"
        }
      />
      {/* 
<WhatWeOffer />
<ProjectsCompleted1 /> */}
      {/* <WorkFlow /> */}

      <section className="pb-xl-5 space-top">
        <div className="container text-center">
          <span class="sec-subtitle">Exploring Our Range of Offerings</span>
          <h1>Offered Services</h1>
        </div>
        <div className="row bg-light-1 mr0 gx-60 flex-row-reverse justify-content-between align-items-center">
          <div
            className=" col-xl-6 pt-5 z-index-common wow fadeInUp"
            data-wow-delay="0.3s"
          >
            <div className="img-box3  pt-5 img-00">
              <div className="img-7">
                <img
                  src="assets/img/about/why-choose-1.jpeg"
                  alt="about thumbnail"
                />
              </div>
            </div>
          </div>
          <div
            className="accordion  col-xl-6  accordion-style2  container pt-5 pb-5"
            id="faqVersion1"
          >
            <div className="accordion-item ml-5">
              <div className="accordion-header" id="headingOne">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Renewable Project Development
                </button>
              </div>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#faqVersion1"
              >
                <div className="accordion-body">
                  <p>
                    At Datta Infra, we specialize in all stages of renewable
                    project development, starting from feasibility studies and
                    site selection to project planning, design, and execution.
                    We conduct thorough assessments of potential project sites,
                    analyzing factors such as solar irradiance, wind speed, land
                    availability, and grid connectivity. With our keen
                    understanding of regulatory requirements and environmental
                    considerations, we ensure that projects are developed in
                    compliance with local laws and regulations. We work closely
                    with our clients to tailor solutions that maximize energy
                    production while minimizing environmental impact.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item ml-5">
              <div className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Transmission Lines and Substations
                </button>
              </div>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#faqVersion1"
              >
                <div className="accordion-body">
                  <p>
                    At Datta Infra, we have a proven track record in the design,
                    construction, and maintenance of transmission lines and
                    substations for renewable energy projects. We possess the
                    technical expertise to handle projects of varying
                    complexities, from small-scale installations to large-scale
                    grid integration projects. By leveraging innovative
                    technologies and best practices, we ensure the efficient and
                    reliable transmission of electricity from renewable energy
                    sources to end consumers. Our focus on safety, reliability,
                    and sustainability ensures that transmission infrastructure
                    meets the highest standards of performance and resilience.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item ml-5">
              <div className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Engineering, Procurement, and Construction (EPC) Projects
                </button>
              </div>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#faqVersion1"
              >
                <div className="accordion-body">
                  <p>
                    At Datta Infra, we offer comprehensive EPC services for
                    renewable power projects on a turnkey basis. From conceptual
                    design to commissioning, we manage every aspect of the
                    project, including engineering, procurement, construction,
                    and quality assurance. Our experienced project management
                    teams ensure seamless coordination between various
                    stakeholders, subcontractors, and vendors to deliver
                    projects on time and within budget. Our commitment to
                    quality and excellence ensures that projects meet or exceed
                    client expectations while adhering to industry standards and
                    best practices.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item ml-5">
              <div className="accordion-header" id="headingFour">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  Operations and Maintenance (O&M)
                </button>
              </div>
              <div
                id="collapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#faqVersion1"
              >
                <div className="accordion-body">
                  <p>
                    At Datta Infra, we provide end-to-end O&M services for
                    renewable energy projects, ensuring optimal performance,
                    reliability, and longevity. Our proactive maintenance
                    approach includes regular inspections, preventive
                    maintenance, and troubleshooting to identify and address
                    potential issues before they escalate. Our dedicated O&M
                    teams leverage advanced monitoring systems and predictive
                    analytics to optimize asset performance and minimize
                    downtime. By prioritizing safety, efficiency, and
                    performance, we help our clients maximize the return on
                    investment and extend the lifespan of their renewable energy
                    assets.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item ml-5">
              <div className="accordion-header" id="headingFive">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Pre-Development Solutions
                </button>
              </div>
              <div
                id="collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#faqVersion1"
              >
                <div className="accordion-body">
                  <p>
                    At Datta Infra, we offer pre-development solutions for solar
                    and wind projects, assisting our clients in navigating the
                    complex process of project development. Our services include
                    site selection, feasibility studies, resource assessment,
                    permitting, and regulatory compliance. Our experienced team
                    helps clients identify suitable project sites, assess
                    project feasibility, and navigate regulatory requirements to
                    expedite the project development process. By providing
                    comprehensive pre-development support, we enable our clients
                    to make informed decisions and mitigate risks at an early
                    stage.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item ml-5">
              <div className="accordion-header" id="headingSix">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  Project Financing and Partnerships
                </button>
              </div>
              <div
                id="collapseSix"
                className="accordion-collapse collapse"
                aria-labelledby="headingSix"
                data-bs-parent="#faqVersion1"
              >
                <div className="accordion-body">
                  <p>
                    At Datta Infra, we assist our clients in securing project
                    financing and forming strategic partnerships for renewable
                    energy projects. Our financial experts help clients assess
                    financing options, structure financing packages, and secure
                    funding from banks, financial institutions, and investors.
                    We also facilitate strategic partnerships with industry
                    players, suppliers, contractors, and technology providers to
                    leverage synergies, reduce costs, and accelerate project
                    timelines. By providing end-to-end financial and strategic
                    advisory services, we empower our clients to overcome
                    financial barriers and unlock the full potential of their
                    renewable energy projects.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item ml-5">
              <div className="accordion-header" id="headingSeven">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  Consulting and Advisory Services
                </button>
              </div>
              <div
                id="collapseSeven"
                className="accordion-collapse collapse"
                aria-labelledby="headingSeven"
                data-bs-parent="#faqVersion1"
              >
                <div className="accordion-body">
                  <p>
                    At Datta Infra, we offer consulting and advisory services to
                    clients in the renewable energy sector, providing strategic
                    insights, market analysis, and project planning assistance.
                    Our team of experts collaborates closely with clients to
                    identify opportunities, assess risks, and develop customized
                    solutions tailored to their specific needs and objectives.
                    Our consulting services cover a wide range of areas,
                    including project development, financing, regulatory
                    compliance, technology assessment, and market intelligence.
                    By leveraging our industry expertise and market knowledge,
                    we help our clients make informed decisions and navigate the
                    complexities of the renewable energy landscape.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <WhyChooseUs2 />
      <Howwework />
    </>
  );
};

export default Services;
