import React from "react";

const GoogleMap = () => {
  return (
    <>
      <div className="container space-bottom">
        <div className="ratio ratio-21x9">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.609709841416!2d77.0829165745635!3d28.40085389456538!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d23d5f6b9dbd3%3A0x92e14f418c1c63a3!2sAIPL%20Business%20Club!5e0!3m2!1sen!2sin!4v1719236508311!5m2!1sen!2sin"
            width="800"
            height="400"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default GoogleMap;
