
import React,{useEffect,useState} from 'react'
import BreadCrum from './BreadCrum';
import { useLocation } from 'react-router-dom';

const ProjectDetail = () => {

    const location = useLocation()
    const [project,setProject] = useState()
    const { id } = location.state;
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BASEURL}/api/projects/${id}?populate=*`);
                
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
    
                const data = await response.json();
                setProject(data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
                // Handle the error, e.g., display an error message to the user
            }
        };    
        fetchData();
    }, []);
    
  return (
   <>


<BreadCrum title={"Projects"} parent={"projects"} child={`${project?.attributes.name}`}/>
   



<section className="vs-blog-wrapper blog-details space-top space-extra-bottom">
        <div className="container">
            <div className="row gx-40">
                <div className="col-lg-12">
                
        <h2 className="sec-title mb-4 col-11 text-center ">{project?.attributes.name}</h2>
        <div className="container ">

       
   
        <div className="row gx-30 gy-3">

        <div className="col-xl-10 wow fadeInUp m-auto" data-wow-delay="0.3s" >
                    <div className="project-style1 layout3">
                        <div className="project-img"><a ><img src={`${process.env.REACT_APP_BASEURL}${project?.attributes.image.data.attributes.url}`} alt="project image" style={{height:"300px", objectFit:"cover"}}/></a></div>
                        <div className="project-content">
                            <div className="project-body">
                                <span className="project-category">{project?.attributes.energytype}</span>
                                <h3 className="project-title2 h4"><a className="text-inherit">{project?.attributes.name}</a></h3>
                                <div className="project-energy">
                                    <span className="project-energytotal h4">{project?.attributes.watt} <span className="quantity">{project?.attributes.powerunit} </span></span>
                                    <p className="project-energytext">of clean energy per year</p>
                                </div>
                            </div>
                            <div className="shape-dotted"></div>

                            
                        </div>
                    </div>
                    <div className=" align-self-center text-center text-xl-center wow fadeInUp " data-wow-delay="0.5s">
        <p className=" fs-md mb-xxl-4 pb-xl-2 mt-n1 justify">Datta Infra founded by Varchasvi Gagal and has established itself as a prominent force within India's leading land aggregation and development sector. Our core mission revolves around providing an organized and sustainable solution for land consolidation and Transmission line, thereby strengthening the renewable energy landscape.</p>
        
        <p className=" fs-md mb-xxl-4 pb-xl-2 mt-n1 justify">With a current RE asset portfolio of 2.5 GW, Our Civil work and Survey Testing, boosting our very own Ramming Machine for efficient commercial installation. Our methodology includes the utilization of TSS machines, Drone Survey Footage, Soil Testing and DGPS Technology. </p>
    </div>
                </div>
 </div>

</div>

{/* recent blog-------------------------------------_s */}
                </div>
       
            </div>
        </div>
    </section>
   </>
  )
}

export default ProjectDetail