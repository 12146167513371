import React, { useEffect } from "react";
import BreadCrum from "../../components/common/BreadCrum";
import { useLocation } from "react-router-dom";

const Awards = () => {
  const { pathname } = useLocation();
  let slugName = pathname?.replace("/", "");
  slugName = slugName === "" || slugName === "index" ? "home" : slugName;
  const getData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/pages?filters[Slug][$eq]=${slugName}`
      );
      const result = await response.json();
      document.querySelector("#content").innerHTML =
        result?.data[0]?.attributes?.Content;
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <BreadCrum
        title={"Download"}
        parenttitle={"About Us"}
        parent={"about"}
        child={"Download"}
        slogan={
          "Building Tomorrow's World: Datta Infra's Dedication to Renewable Energy Excellence"
        }
      />
      {false && (
        <section className=" space-top space-extra-bottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className="title-area">
                  <h2 className="sec-title h1">
                    Award-winning renewable energy solutions
                  </h2>
                </div>
                <div className="row">
                  <div className="col-xl-10">
                    <div className="progress-box">
                      <h3 className="progress-box__title">Wind Turbines</h3>
                      <span className="progress-box__number">85%</span>
                      <div className="progress-box__progress">
                        <div
                          className="progress-box__bar"
                          role="progressbar"
                          style={{ width: "85%" }}
                          aria-valuenow="85"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                    <div className="progress-box">
                      <h3 className="progress-box__title">Hybrid Energy</h3>
                      <span className="progress-box__number">62%</span>
                      <div className="progress-box__progress">
                        <div
                          className="progress-box__bar"
                          role="progressbar"
                          style={{ width: "62%" }}
                          aria-valuenow="62"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pt-3"></div>
                <div className="img-box2">
                  <div className="img-1">
                    <img src="assets/img/about/why-choose-1.jpg" alt="thumb" />
                  </div>
                  <div className="shape-dotted jump"></div>
                </div>
              </div>
              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.4s">
                <p className="fs-md mb-5 pe-xxl-5 mt-n1">
                  Datta Infra is a company run by an innovative and experienced
                  group of professionals who provide end-to-end services for
                  land aggregation, contracting, transmission line, and
                  obtaining connectivity approvals for clients and SPV model
                  based power projects in India.
                </p>
                <div className="vs-media about-media ">
                  <div className="media-icon">
                    <img
                      src="assets/img/icon/why-choose-1.png"
                      alt="feature icon"
                    />
                  </div>
                  <div className="media-body">
                    <span className="media-title h5">Award winning team</span>
                    <p className="media-info">
                      Datta Infra is a company run by an innovative and
                      experienced group of professionals who provide end-to-end
                      services
                    </p>
                  </div>
                </div>
                <div className="vs-media about-media ">
                  <div className="media-icon">
                    <img
                      src="assets/img/icon/why-choose-2.png"
                      alt="feature icon"
                    />
                  </div>
                  <div className="media-body">
                    <span className="media-title h5">Money Back Guarantee</span>
                    <p className="media-info">
                      Datta Infra is a company run by an innovative and
                      experienced group of professionals who provide end-to-end
                      services
                    </p>
                  </div>
                </div>
                <div className="vs-media about-media ">
                  <div className="media-icon">
                    <img
                      src="assets/img/icon/why-choose-3.png"
                      alt="feature icon"
                    />
                  </div>
                  <div className="media-body">
                    <span className="media-title h5">Easy Installation</span>
                    <p className="media-info">
                      Datta Infra is a company run by an innovative and
                      experienced group of professionals who provide end-to-end
                      services
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {true && (
        <section class="space-top space-extra-bottom">
          <div class="container">
            <div class="row justify-content-center">
              <div
                class="col-lg-10 col-xl-8 text-center wow fadeInUp"
                data-wow-delay="0.3s"
              >
                <div class="title-area ">
                  <h2 class="sec-title h1 mb-2">Our Certificates </h2>
                  <p class="sec-text px-xl-5 mx-xxl-4">
                    Since being founded in 1995, operated with a focus on
                    quality, honesty, reliability and customer satisfaction,
                    resulting in exceptional customer feedback and a wide range
                    of regional, national and international awards
                  </p>
                </div>
              </div>
            </div>
            <h3 class="award-wrap-title">CERTIFICATES OF REGISTRATION</h3>
            <div class="award-wrap1 wow fadeInUp" data-wow-delay="0.3s">
              <div class="row">
                <div class="col-md-4">
                  <div class="award-style1">
                    <a target="_blank" href="assets/pdf/ISO_9001_QMS.pdf">
                      <div class="award-img">
                        <img src="assets/pdf/ISO-1.png" alt="award" />
                      </div>
                    </a>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="award-style1">
                    <a target="_blank" href="assets/pdf/ISO_14001_EMS.pdf">
                      <div class="award-img">
                        <img src="assets/pdf/ISO-2.png" alt="award" />
                      </div>
                    </a>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="award-style1">
                    <a target="_blank" href="assets/pdf/ISO_45001_OHMSM.pdf">
                      <div class="award-img">
                        <img src="assets/pdf/ISO-3.png" alt="award" />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Awards;
