import React, { useEffect } from "react";
import BreadCrum from "../../components/common/BreadCrum";
import Clients from "../../components/Clients";
import { useLocation } from "react-router-dom";

const OurPartners = () => {
  const { pathname } = useLocation();
  let slugName = pathname?.replace("/", "");
  slugName = slugName === "" || slugName === "index" ? "home" : slugName;
  const getData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/pages?filters[Slug][$eq]=${slugName}`
      );
      const result = await response.json();
      document.querySelector("#content").innerHTML =
        result?.data[0]?.attributes?.Content;
    } catch (error) {
      console.log("Error:", error);
    }
  };
 
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <BreadCrum parenttitle={"About Us"} parent={"about"} title={"Core Purpose and Values"} child={"Core Purpose and Values"} slogan={"Unleashing Potential, Redefining Possibilities: Datta Infra Leads the Way in Renewable Energy Innovation"}/>
      <div id="content"></div>
      {false && <section className=" space">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-xl-9 wow fadeInUp" data-wow-delay="0.3s">
              <div className="title-area">
                <svg
                  className="sec-icon"
                  width="68"
                  height="67"
                  viewBox="0 0 68 67"
                >
                  <path
                    className="sec-shape"
                    d="M926,1225l63-39-21-1,26-27-58,35,21,1"
                    transform="translate(-926 -1158)"
                  ></path>
                </svg>
                {/* <span className="sec-subtitle">PROJECTS SUPPLIER AND INSTALLER</span> */}
                <h2 className="sec-title h1">
                  Professional Successful Renewable Energy Storage Solutions
                </h2>
              </div>
            </div>
          </div>
          <div className="row flex-row-reverse">
            <div
              className="col-xl-7 mb-40 mb-xl-0 wow fadeInUp"
              data-wow-delay="0.4s"
            >
              <div className="img-box6">
                <div className="img-1">
                  <img src="assets/img/about/about-1-1.jpg" alt="about" />
                </div>
                <div className="img-2">
                  <img src="assets/img/about/about-1-2.jpg" alt="about" />
                </div>
                <div className="img-shape1"></div>
                <div className="shape-dotted jump"></div>
              </div>
            </div>
            <div
              className="col-xl-5 text-start text-xl-start wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div >
                <h2>Key Partnerships</h2>

                <ul className="test-start">
                  <li>
                    <strong>Land Aggregation Partners:</strong> Collaborating
                    with leading entities in land aggregation to ensure
                    efficient and sustainable use of resources.
                  </li>

                  <li>
                    <strong>Transmission Line Collaborations:</strong>{" "}
                    Partnering with industry leaders in the transmission sector
                    to enhance the reliability of our renewable energy projects.
                  </li>

                  <li>
                    <strong>Technology Providers:</strong> Utilizing
                    cutting-edge technology from our partners to enhance our
                    Civil work, Survey Testing, and overall project efficiency.
                    This includes partnerships for Ramming Machine development.
                  </li>

                  <li>
                    <strong>Renewable Energy Investors:</strong> Building strong
                    ties with investors in the renewable energy sector to
                    support the growth of our RE asset portfolio.
                  </li>

                  <li>
                    <strong>Survey and Testing Technology Partners:</strong>{" "}
                    Collaborating with providers of TSS machines, DGPS
                    Technology, and Drone Survey solutions to ensure precision
                    in our project methodologies.
                  </li>
                </ul>

                {/* <a href="" className="vs-btn ">Read More</a> */}
              </div>{" "}
              <h3 className="h4 mb-1">Thomas Marker</h3>
              <p className="text-title fs-xs mb-4">
                Director of Manufacturing facilities company
              </p>
              {/* <img src="assets/img/about/sign.png" alt="sign"/> */}
            </div>
          </div>
        </div>
      </section>}
      <Clients />

      {/* <Clients /> */}
    </>
  );
};

export default OurPartners;
