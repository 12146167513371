import React from "react";

export const WelcometoDatta = () => {
  return (
    <div>
      <section className="pb-xl-5">
        <div className="container">
        <span className="sec-subtitle text-center">Empowering Communities Through Sustainable Energy Solutions</span>

          <h3 className="text-center pb-5">Welcome to Datta Power Infra</h3>
          <p>
            At Datta Power Infra, we are committed to providing innovative
            solutions and reliable services in the power infrastructure sector.
            With a focus on excellence and sustainability, we specialize in
            delivering end-to-end solutions tailored to meet the evolving needs
            of our clients.
          </p>
          <h4>Our Mission</h4>
          <p>Our mission is to empower industries, communities, and individuals with efficient and sustainable power infrastructure solutions. We strive to exceed expectations by delivering high-quality services, fostering long-term partnerships, and driving positive change in the energy sector.
          </p>
         {/* <section class=" space-top">
            <div class="container">
              <div class="row">
                <div
                  class="col-xl-8 col-xxl-8 z-index-common wow fadeInUp"
                  data-wow-delay="0.3s"
                >
                  <div class="row gx-0 justify-content-center justify-content-xl-start">
                    <div class="col-md-7 col-lg-7 col-xxl-8 text-center text-xl-start">
                      <span class="sec-subtitle">WHY CHOOSE US</span>
                      <h2 class="sec-title h1 mb-4 pb-2">What We Offer</h2>
                    </div>
                    <div class="col-md-6 feature-style2">
                      <div class="feature-body">
                        <h3 class="feature-title h5">Turnkey Solutions</h3>
                        <p class="feature-text">
                          From conceptualization to execution, we offer
                          comprehensive turnkey solutions for power
                          infrastructure projects, including design,
                          engineering, procurement, construction, and
                          commissioning.
                        </p>
                      </div>
                    </div>
                    <div class="col-md-6 feature-style2">
                      <div class="feature-body">
                        <h3 class="feature-title h5">Project Management</h3>
                        <p class="feature-text">
                          With our expertise in project management, we ensure
                          seamless execution, timely delivery, and
                          cost-effective solutions for projects of any scale or
                          complexity.
                        </p>
                      </div>
                    </div>
                    <div class="col-md-6 feature-style2">
                      <div class="feature-body">
                        <h3 class="feature-title h5">Renewable Energy</h3>
                        <p class="feature-text">
                          Embracing the shift towards renewable energy, we
                          specialize in the development and implementation of
                          solar, wind, and other sustainable energy solutions,
                          contributing to a greener and more sustainable future.
                        </p>
                      </div>
                    </div>
                    <div class="col-md-6 feature-style2">
                      <div class="feature-body">
                        <h3 class="feature-title h5">
                          Maintenance and Support
                        </h3>
                        <p class="feature-text">
                          We provide ongoing maintenance and support services to
                          optimize the performance and longevity of power
                          infrastructure assets, ensuring maximum reliability
                          and efficiency.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-xl-4 col-xxl-4 pt-5 pt-xl-0 wow fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <div class="img-box5">
                    <div class="img-1">
                      <img
                        src="assets/img/about/about-6-2.jpg"
                        alt="thumbnail"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
  </section>*/}

          
          <section class="space-top space-bottom">
          <div class="container">
              <div class="row flex-row-reverse">
                  <div class="col-xl-6 col-xxl mb-40 mb-xl-0 wow fadeInUp" data-wow-delay="0.3s">
                      <div class="img-box1 space-bottom" style={{top:"80px"}}>
                          <div class="img-1">
                              <img src="assets/img/about/8_enhanced.jpeg" alt="about" class="w-100"/>
                           </div>
                      </div>
                  </div>
                  <div class="col-xl-6 col-xxl wow fadeInUp" data-wow-delay="0.4s">
                       <h3 class="sec-title h1 mb-5">What We Offer</h3>
                      <div class="process-style1">
                          <div class="process-item hww">
                              <h3 class="process-title h5">Turnkey<br/>Solutions</h3>
                              <div class="process-content">
                                  <span class="process-number">1</span>
                                  <p class="process-text">From conceptualization to execution, we offer comprehensive turnkey solutions for power infrastructure projects, including design, engineering, procurement, construction, and commissioning.
                                  </p>
                              </div>
                          </div>
                          <div class="process-item hww">
                              <h3 class="process-title h5">Project<br/>Management</h3>
                              <div class="process-content">
                                  <span class="process-number">2</span>
                                  <p class="process-text">With our expertise in project management, we ensure seamless execution, timely delivery, and cost-effective solutions for projects of any scale or complexity.</p>
                              </div>
                          </div>
                          <div class="process-item hww">
                              <h3 class="process-title h5">Renewable<br/>Energy</h3>
                              <div class="process-content">
                                  <span class="process-number">3</span>
                                  <p class="process-text">Embracing the shift towards renewable energy, we specialize in the development and implementation of solar, wind, and other sustainable energy solutions, contributing to a greener and more sustainable future.
                                  </p>
                              </div>
                          </div>
                          <div class="process-item hww">
                              <h3 class="process-title h5">Maintenance<br/>and Support</h3>
                              <div class="process-content">
                                  <span class="process-number">4</span>
                                  <p class="process-text">We provide ongoing maintenance and support services to optimize the performance and longevity of power infrastructure assets, ensuring maximum reliability and efficiency.
                                  </p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <section class="space-bottom">
          <div class="container">
              <div class="row flex-row-reverse">
                  <div class="col-xl-6 col-xxl mb-40 mb-xl-0 wow fadeInUp" data-wow-delay="0.3s">
                      <div class="img-box1 space-bottom" style={{top:"140px"}}>
                          <div class="img-1">
                              <img src="assets/img/about/5.jpeg" alt="about" class="w-100"/>
                          </div>
                      </div>
                  </div>
                  <div class="col-xl-6 col-xxl wow fadeInUp" data-wow-delay="0.4s">
                       <h3 class="sec-title h1 mb-5 pt-5">Choose Us: Your Partner for Success</h3>
                      <div class="process-style1">
                          <div class="process-item hww">
                              <h3 class="process-title h5">Expertise</h3>
                              <div class="process-content">
                                  <span class="process-number">1</span>
                                  <p class="process-text">With a team of experienced professionals and industry experts, we bring extensive knowledge and expertise to every project, ensuring exceptional results and customer satisfaction.
                                  </p>
                              </div>
                          </div>
                          <div class="process-item hww">
                              <h3 class="process-title h5">Innovation</h3>
                              <div class="process-content">
                                  <span class="process-number">2</span>
                                  <p class="process-text">We leverage cutting-edge technologies and innovative solutions to address the unique challenges of the power infrastructure sector, driving efficiency, reliability, and sustainability.</p>
                              </div>
                          </div>
                          <div class="process-item hww">
                              <h3 class="process-title h5">Reliability</h3>
                              <div class="process-content">
                                  <span class="process-number">3</span>
                                  <p class="process-text">At Datta Power Infra, reliability is at the core of everything we do. We are committed to delivering reliable solutions and services that meet the highest standards of quality and performance.
                                  </p>
                              </div>
                          </div>
                          <div class="process-item hww">
                              <h3 class="process-title h5">Customer<br/> Focus</h3>
                              <div class="process-content">
                                  <span class="process-number">4</span>
                                  <p class="process-text">We prioritize customer satisfaction and strive to exceed expectations by understanding our client&apos;s needs, providing personalized solutions, and delivering exceptional value at every stage of the project lifecycle.
                                  </p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
          <h6>Get in Touch</h6>
      <p>Whether you&apos;re looking to embark on a new power infrastructure project, explore renewable energy solutions, or require ongoing maintenance and support, Datta Power Infra is here to help. Contact us today to learn more about our services and how we can partner with you to achieve your goals
      </p>
        </div>
      </section>
    </div>
  );
};
