import React, { useEffect, useState } from "react";
import BreadCrum from "../../components/common/BreadCrum";
import { useLocation } from "react-router-dom";
// import ReadMore from 'read-more-react';
import ReadMore from "../../components/common/ReadMore";

const Leadership = () => {
  const { pathname } = useLocation();
  let slugName = pathname?.replace("/", "");
  slugName = slugName === "" || slugName === "index" ? "home" : slugName;
  const getData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/pages?filters[Slug][$eq]=${slugName}`
      );
      const result = await response.json();
      document.querySelector("#content").innerHTML =
        result?.data[0]?.attributes?.Content;
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <BreadCrum
        parenttitle={"About Us"}
        parent={"about"}
        title={"Leadership Team"}
        child={"Leadership Team"}
        slogan={
          "Leading the Charge: Datta Infra Sets New Standards in Renewable Project Development and Execution"
        }
      />

      {true && (
        <section class="space-top space-extra-bottom">
          <div class="container">
            <div
              class="title-area text-center wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <span class="sec-subtitle">Our Core Team Leaders</span>
              <h2 class="sec-title h1">Meet Our Team</h2>
            </div>
            <div class="container">
              <div class="row">
                <div class="team-column col-md-12 col-lg-12">
                  <div class="team-style1 row">
                    <div class="team-img col-md-4 col-sm-3">
                      <img src="assets/img/team/ks.png" alt="team member" />
                  
                    </div>

                    <div class="team-content dash-w col-md-8 col-sm-9">
                      <h3 class="team-name">
                        <a href="team-details.html">Mr. Kuldeep Singh</a>
                      </h3>
                      <span class="team-degi">CHAIRMAN</span>
                 
                      <br />
                      <div class="team-bio team-number">
                        <b>Bio:</b>
                        <div className="container">With a wealth of experience in the renewable energy sector, Mr. Kuldeep Singh leads our board as Chairman. His visionary leadership and strategic guidance steer the direction of our company, driving us towards new heights of success and innovation.</div>
              
                      </div>
                    </div>
                  </div>
                </div>
                <div class="team-column col-md-12 col-lg-12">
                  <div class="team-style1 row">
                    <div class="team-img col-md-4 col-sm-3">
                      <img src="assets/img/team/mvg.png" alt="team member" />
                      
                    </div>
                    <div class="team-content dash-w col-md-8 col-sm-9">
                      <h3 class="team-name">
                        <a href="team-details.html">Mr. Varchasvi Gagal</a>
                      </h3>
                      <span class="team-degi">CEO and MD</span>
                      
                      <br />
                      <div class="team-bio team-number">
                        <b>Bio:</b>
                       <div className="container">As the CEO and Managing Director of our company, Mr. Varchasvi Gagal brings a dynamic vision and unwavering commitment to excellence. With his leadership, we continue to set benchmarks in the renewable energy industry, driving growth, and delivering value to our stakeholders.</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="team-column col-md-12 col-lg-12">
                  <div class="team-style1 row">
                    <div class="team-img col-md-4 col-sm-3">
                      <img src="assets/img/team/mrkg.png" alt="team member" />
                    
                    </div>
                    <div class="team-content dash-w col-md-8 col-sm-9">
                      <h3 class="team-name">
                        <a href="team-details.html">Mr. Rajender Kr. Gupta</a>
                      </h3>
                      <span class="team-degi">LEGAL AND CONTRACTS</span>
                    
                      <br />
                      <div class="team-bio team-number">
                        <b>Bio:</b>
                       <div className="container">Mr. Rajender Kr. Gupta serves as our expert in legal affairs and contracts management. With his extensive knowledge and expertise, he ensures that our operations comply with regulatory requirements and contractual obligations, safeguarding the interests of our company and our clients.</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="team-column col-md-12 col-lg-12">
                  <div class="team-style1 row">
                    <div class="team-img col-md-4 col-sm-3">
                      <img src="assets/img/team/mpc.png" alt="team member" />
                      
                    </div>
                    <div class="team-content dash-w col-md-8 col-sm-9">
                      <h3 class="team-name">
                        <a href="team-details.html"> Mr. Paramjit Chattwal</a>
                      </h3>
                      <span class="team-degi">Government Relations</span>
                     
                      <br />
                      <div class="team-bio team-number">
                        <b>Bio:</b>
                     <div className="container">Mr. Paramjit Chattwal is our trusted liaison for government relations, fostering strong partnerships and collaborations with regulatory authorities and government agencies. His strategic insights and diplomatic approach play a crucial role in navigating the regulatory landscape and driving policy advocacy initiatives.</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="team-column col-md-12 col-lg-12">
                  <div class="team-style1 row">
                    <div class="team-img col-md-4 col-sm-3">
                      <img src="assets/img/team/msk.png" alt="team member" />
                     
                    </div>
                    <div class="team-content dash-w col-md-8 col-sm-9">
                      <h3 class="team-name">
                        <a href="team-details.html">Mr. Shobhit Kumar</a>
                      </h3>
                      <span class="team-degi">COO Alumnus IIT-D & IIM-L</span>
               
                      <br />
                      <div class="team-bio team-number">
                        <b>Bio:</b>
                      <div className="container">Mr. Shobhit Kumar, an alumnus of IIT-Delhi and IIM-Lucknow, serves as our Chief Operating Officer (COO). With his strong background in engineering and management, he brings a unique blend of technical expertise and business acumen to our leadership team, driving operational excellence and strategic initiatives.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Leadership;
