import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

export const Howwework = () => {
  const [activeButton, setActiveButton] = useState(0);

  const handleClick = (buttonId) => {
    setActiveButton(buttonId);
  };

  return (
    <div>
      <section class="bg-light-1 overflow-hidden space-top space-extra-bottom">
        <div class="container">
          <div
            class="title-area text-center wow fadeInUp"
            data-wow-delay="0.3s"
          >
            <span class="sec-subtitle">WORK FLOW</span>
            <h2 class="sec-title h1">How We Work</h2>
          </div>
          <Tabs>
            <div class="row gx-0">
              <div
                class="col-xl-4 col-xxl-auto wow fadeInUp"
                data-wow-delay="0.4s"
              >
                <div
                  class="project-tab1 vs-slider-tab"
                  data-asnavfor=".project-slider2"
                >
                  <TabList>
                    <Tab>
                      <button
                        class="tab-btn active"
                        className={
                          activeButton === 1 || activeButton === 0
                            ? "tab-btn active"
                            : ""
                        }
                        onClick={() => handleClick(1)}
                      >
                        Project Development and Planning
                      </button>
                    </Tab>
                    <Tab>
                      <button
                        class="tab-btn "
                        className={activeButton === 2 ? " tab-btn active" : ""}
                        onClick={() => handleClick(2)}
                      >
                        Engineering and Design
                      </button>
                    </Tab>
                    <Tab>
                      <button
                        class="tab-btn "
                        className={activeButton === 3 ? "tab-btn active" : ""}
                        onClick={() => handleClick(3)}
                      >
                        Procurement and Supply Chain Management
                      </button>
                    </Tab>
                    <Tab>
                      <button
                        class="tab-btn "
                        className={activeButton === 4 ? "tab-btn active" : ""}
                        onClick={() => handleClick(4)}
                      >
                        Construction and Installation
                      </button>
                    </Tab>
                    <Tab>
                      <button
                        class="tab-btn "
                        className={activeButton === 5 ? "tab-btn active" : ""}
                        onClick={() => handleClick(5)}
                      >
                        Commissioning and Testing
                      </button>
                    </Tab>
                    <Tab>
                      <button
                        class="tab-btn "
                        className={activeButton === 6 ? "tab-btn active" : ""}
                        onClick={() => handleClick(6)}
                      >
                        Operations and Maintenance (O&M)
                      </button>
                    </Tab>
                    <Tab>
                      <button
                        class="tab-btn "
                        className={activeButton === 7 ? "tab-btn active" : ""}
                        onClick={() => handleClick(7)}
                      >
                        Continuous Improvement and Innovation
                      </button>
                    </Tab>
                  </TabList>
                </div>
              </div>
              <div
                class="col-xl wow fadeInUp"
                data-wow-delay="0.5s"
               >
                <div
                  class="project-slider "
                  data-dots="true"
                  data-slide-show="1"
                  data-fade="true"
                >
                  <TabPanel>
                    <div class="project-style2">
                      <div class="project-img">
                        <img
                          src="assets/img/project/proj-2-1.jpg"
                          alt="thumbnail"
                        />
                      </div>
                      <div class="media-body">
                        <div class="project-head">
                          <span class="project-category">SOLAR ENERGY</span>
                          <h3 class="project-title h4">
                            Project Development and Planning
                          </h3>
                        </div>
                        <p class="project-text">
                          Datta Power Infra begins by identifying and assessing
                          opportunities for renewable energy projects. This
                          includes conducting feasibility studies, site
                          assessments, and resource evaluations to determine the
                          viability and potential of each project. Our
                          experienced team of professionals analyzes various
                          factors such as solar irradiance, wind speed, land
                          availability, regulatory requirements, and market
                          conditions to develop a comprehensive project plan.
                        </p>
                 
                       
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div class="project-style2">
                      <div class="project-img">
                        <img
                          src="assets/img/project/proj-2-2.jpg"
                          alt="thumbnail"
                        />
                      </div>
                      <div class="media-body">
                        <div class="project-head">
                          <span class="project-category"></span>
                          <h3 class="project-title h4">
                            Engineering and Design
                          </h3>
                        </div>
                        <p class="project-text">
                          Once a project is deemed feasible, Datta Power
                          Infra&apos;s engineering team takes charge of
                          designing the project layout, system components, and
                          infrastructure requirements. Using state-of-the-art
                          technology and industry best practices, we create
                          detailed engineering drawings, specifications, and
                          plans to ensure the efficient and effective
                          implementation of the project.
                        </p>
                        <span class="project-energytotal h4">
                          235,099 <span class="quantity">kWh</span>
                        </span>
                        <p class="project-energytext">
                          of clean energy per year
                        </p>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div class="project-style2">
                      <div class="project-img">
                        <img
                          src="assets/img/project/proj-2-3.jpg"
                          alt="thumbnail"
                        />
                      </div>
                      <div class="media-body">
                        <div class="project-head">
                          <span class="project-category"></span>
                          <h3 class="project-title h4">
                            Procurement and Supply Chain Management
                          </h3>
                        </div>
                        <p class="project-text">
                          Datta Power Infra manages the procurement process to
                          source high-quality materials, equipment, and services
                          required for project construction. Our procurement
                          team leverages strategic partnerships with suppliers
                          and vendors to secure competitive pricing, timely
                          delivery, and adherence to quality standards. We
                          ensure that all materials and components meet the
                          project specifications and regulatory requirements.
                        </p>
                        <span class="project-energytotal h4">
                          235,099 <span class="quantity">kWh</span>
                        </span>
                        <p class="project-energytext">
                          of clean energy per year
                        </p>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div class="project-style2">
                      <div class="project-img">
                        <img
                          src="assets/img/project/proj-2-4.jpg"
                          alt="thumbnail"
                        />
                      </div>
                      <div class="media-body">
                        <div class="project-head">
                          <span class="project-category"></span>
                          <h3 class="project-title h4">
                            Construction and Installation
                          </h3>
                        </div>
                        <p class="project-text">
                          With meticulous planning and execution, Datta Power
                          Infra oversees the construction and installation phase
                          of the project. Our experienced construction teams
                          work diligently to build the necessary infrastructure,
                          including solar panels, wind turbines, transmission
                          lines, substations, and other components. We adhere to
                          strict safety protocols, quality standards, and
                          project timelines to ensure successful project
                          completion.
                        </p>
                        <span class="project-energytotal h4">
                          235,099 <span class="quantity">kWh</span>
                        </span>
                        <p class="project-energytext">
                          of clean energy per year
                        </p>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div class="project-style2">
                      <div class="project-img">
                        <img
                          src="assets/img/project/proj-2-5.jpg"
                          alt="thumbnail"
                        />
                      </div>
                      <div class="media-body">
                        <div class="project-head">
                          <span class="project-category">SOLAR ENERGY</span>
                          <h3 class="project-title h4">
                            Commissioning and Testing
                          </h3>
                        </div>
                        <p class="project-text">
                          After construction is complete, Datta Power Infra
                          conducts comprehensive commissioning and testing to
                          verify the functionality, performance, and reliability
                          of the project. We conduct rigorous testing
                          procedures, including system integration tests,
                          performance tests, and quality inspections, to
                          identify and address any issues or deficiencies. Our
                          goal is to ensure that the project meets or exceeds
                          the specified requirements and delivers optimal
                          performance.
                        </p>
                        <span class="project-energytotal h4">
                          235,099 <span class="quantity">kWh</span>
                        </span>
                        <p class="project-energytext">
                          of clean energy per year
                        </p>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div class="project-style2">
                      <div class="project-img">
                        <img
                          src="assets/img/project/proj-2-5.jpg"
                          alt="thumbnail"
                        />
                      </div>
                      <div class="media-body">
                        <div class="project-head">
                          <span class="project-category">SOLAR ENERGY</span>
                          <h3 class="project-title h4">
                            Operations and Maintenance (O&M)
                          </h3>
                        </div>
                        <p class="project-text">
                          Datta Power Infra offers comprehensive operations and
                          maintenance services to ensure the long-term
                          performance and profitability of the project. Our
                          dedicated O&M teams monitor, inspect, and maintain the
                          project infrastructure regularly to maximize uptime,
                          efficiency, and lifespan. We employ advanced
                          monitoring systems, predictive maintenance techniques,
                          and proactive measures to minimize downtime and
                          optimize energy production.
                        </p>
                        <span class="project-energytotal h4">
                          235,099 <span class="quantity">kWh</span>
                        </span>
                        <p class="project-energytext">
                          of clean energy per year
                        </p>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div class="project-style2">
                      <div class="project-img">
                        <img
                          src="assets/img/project/proj-2-5.jpg"
                          alt="thumbnail"
                        />
                      </div>
                      <div class="media-body">
                        <div class="project-head">
                          <span class="project-category">SOLAR ENERGY</span>
                          <h3 class="project-title h4">
                            Continuous Improvement and Innovation
                          </h3>
                        </div>
                        <p class="project-text">
                          Datta Power Infra is committed to continuous
                          improvement and innovation in all aspects of our work.
                          We continuously evaluate our processes, technologies,
                          and practices to identify opportunities for
                          optimization, efficiency gains, and cost savings. By
                          staying abreast of industry trends, emerging
                          technologies, and best practices, we strive to remain
                          at the forefront of the renewable energy sector and
                          deliver maximum value to our clients.
                        </p>
                        <span class="project-energytotal h4">
                          235,099 <span class="quantity">kWh</span>
                        </span>
                        <p class="project-energytext">
                          of clean energy per year
                        </p>
                      </div>
                    </div>
                  </TabPanel>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </section>

      <section className="container space-top space-bottom">
        <div>
          <p class="pe-xxl-5 fs-md mb-xxl-4 mt-6 justify hww">
            This comprehensive approach to project development, execution, and
            management enables Datta Power Infra to deliver turnkey solutions
            that meet the highest standards of quality, performance, and
            sustainability. By leveraging our expertise, experience, and
            commitment to excellence, we empower our clients to achieve their
            renewable energy goals and contribute to a cleaner, greener future.
          </p>
        </div>
      </section>
    </div>
  );
};
