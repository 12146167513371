import React, { useEffect, useState } from "react";
import CareerForm from "./form/CareerForm";
import Slider from "react-slick";
import PreviousArrow from "./Arrowslick";
import NextArrow from "./NextArrow";
import { useLocation } from "react-router-dom";

export const Workwithus = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [activeButton, setActiveButton] = useState(1);
  const [profiles, setProfiles] = useState([]);
  const [department, setDepartment] = useState("Business Development & Tendering");
  const [position, setPosition] = useState( [
    "Business Development & Tendering",
    "Electrical",
    "Execution - Land Acquisition",
    "Human Resource & Admin",
    "Finance & Accounts",
    "Safety",
    "Procurement",
    "Computers and Information Technology",
    "Project Management",
    "Production",
    "Sales",
    "Sales Quality Management",
    "Maintenance"
  ]
  );

  const getData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/careers`
      );
      const result = await response.json();

      // setPosition(result?.data?.map((item) => [item.attributes.Position]));
      setProfiles(
        result?.data?.map((item) => ({
          location: item.attributes.Company,
          position: item.attributes.Position,
          skills: item.attributes.Skills_required[0].children[0].text,
          Skills_description:item.attributes.Skills_description[0].children[0].text,
          experience: item.attributes.Min_Experience,
          date: new Date(item.attributes.updatedAt).toISOString().split("T")[0],
          department: item.attributes.Department[0],
        }))
      );
      setSelectedPosition(result?.data[0]?.attributes.Position);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);


  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: true,
    afterChange: (current) => setActiveButton(current + 1),
    centerPadding: "60px",
    focusOnSelect: true,
    prevArrow: <PreviousArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [selectedPosition, setSelectedPosition] = useState(
    // position[0]
    "Business Development & Tendering"
  );

  useEffect(() => {
    setSelectedPosition(position[activeButton - 1]);
  }, [activeButton, position]);

  const handleClick = (buttonId) => {
    // setActiveButton(buttonId);
  };


  return (
    <div>
      <section className="space-extra-bottom">
        <div className="container">
          <div className="row flex-row-reverse">
            <h2 className="sec-title h1 mb-5 text-center">
              Open Job Positions
            </h2>
            <section className="pb-xl-5 ">
              <div
                className="accordion accordion-style2  container "
                id="faqVersion1"
              >
                <div className="row  gx-0">
                  <Slider {...settings}>
                    {position?.map((pos, index) => (
                      <div key={index} className="accordion-item holder">
                        <div className="accordion-header">
                          <button
                            className={
                              activeButton === index + 1
                                ? "accordion-button wwu active3"
                                : "accordion-button wwu"
                            }
                            onClick={() => {handleClick(index + 1)
                              setDepartment(pos)


                            }}
                          >
                            {pos}
                          </button>
                        </div>
                      </div>
                    ))}
                 
                  </Slider>
                </div>
              </div>
            
            </section>
            <div className="container">
  <div className="row">
    <div className="col-lg-6 mb-4 pt-5">
      <CareerForm selectedPosition={selectedPosition} />
    </div>
    {profiles?.map((profile, index) => (
      <div
        key={index}
        className={
          department === profile.department
            ? "col-lg-6 mb-4 dblock pt-5"
            : "col-lg-6 mb-4 dnone"
        }
      >
        <div className="card rounded shadow">
          <div
            className="card-body rounded-3"
            style={{ backgroundColor: "#ff741bcc" }}
          >
            <h5 className="card-title text-start text-capitalize">
              {profile.position}
            </h5>
            <p className="card-text text-white">
              {profile.location} <br />
              <strong>Posted on </strong> {profile.date}
            </p>
          </div>
          <div className="card-body">
            <p className="card-text">
              <strong>Skills required: </strong>
              {profile.skills}
            </p>
            <ul
              style={{
                padding: "0 0 0 1em",
                margin: 0,
                textAlign: "start",
              }}
            >
              <li>
                Must have {profile.experience} years of experience
              </li>
            </ul>
            <p className="card-text">
              <strong>Experience Level: </strong>
              {profile.experience}
              <p className="card-text">
                {profile.Skills_description}
              </p>
            </p>
            <p className="text-dark text-start fs-5">
              <strong>Send your resume at </strong>
              <a href="mailto:info@dattainfra.com" className="w-50">
                info@dattainfra.com
              </a>
            </p>
          </div>
        </div>
      </div>
    ))}
  </div>
</div>


       
          </div>
        </div>
      </section>
    </div>
  );
};
