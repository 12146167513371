const PreviousArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="slick-arrow slick-prev" onClick={onClick}>
      <img src="assets/img/left-arrow.png"/>
      </div>
    );
  };
  

  export default PreviousArrow 
