import React from 'react'
import BreadCrum from '../components/common/BreadCrum'
import Blog from '../components/common/Blog'

const Blogs = () => {





  return (
<>

<BreadCrum title={"Blogs"} parent={"blogs"} slogan={"Leading the Charge: Datta Infra Sets New Standards in Renewable Project Development and Execution"}/>
<Blog/>

</>
  )
}

export default Blogs