import React from 'react'
import { useNavigate } from 'react-router-dom'

const Error = () => {
    const navigate = useNavigate()
  return (
<>
{/* <!--==============================
    Error Area 
    ==============================--> */}
    <section className="vs-error-wrapper bg-dark  ">
        <div className="container">
            <div className="row flex-row-reverse text-center text-xl-start">
                <div className="col-xl-6 col-xxl-7 text-xl-end">
                    <div className="error-img">
                        <img src="assets/img/shape/error-1-1.png" alt="error image"/>
                    </div>
                </div>
                <div className="col-xl-6 col-xxl-5 align-self-center">
                    <div className="error-content ">
                        <h1 className="error-title text-light">oooops!</h1>
                        <h2 className="error-title2 text-light">Page Not Found</h2>
                        <p className="error-text">the resource you are looking for might have been removed had its name changed or is temporarily</p>
                        {/* <form action="#" className="search-inline">
                            <input type="text" className="form-control" placeholder="Enter Your Keyword...."/>
                            <button><i className="far fa-search"></i></button>
                        </form> */}
                        <a href="/" className="vs-btn"><i className="fas fa-home me-2 pe-1"></i>Back To Home</a>
                    </div>
                </div>
            </div>
        </div>
    </section>


</>
  )
}

export default Error