import React, { useEffect } from "react";
import CompanyOverview from "../components/CompanyOverview";
import ProjectsCompleted1 from "../components/ProjectsCompleted1";
import WorkFlow from "../components/WorkFlow";
import WhyChooseUs from "../components/WhyChooseUs";
import Clients from "../components/Clients";
import BreadCrum from "../components/common/BreadCrum";
import { useLocation } from "react-router-dom";
import { WelcometoDatta } from "../components/welcomedattainfra";
import { Howwework } from "../components/Howwework";

const About = () => {
  const { pathname } = useLocation();
  let slugName = pathname?.replace("/", "");
  slugName = slugName === "" || slugName === "index" ? "home" : slugName;
  const getData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/pages?filters[Slug][$eq]=${slugName}`
      );
      const result = await response.json();
      // console.log("------>",result?.data[0]?.attributes?.Content);
      // document.querySelector("#content").innerHTML =
      //   result?.data[0]?.attributes?.Content;
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <BreadCrum
        title={"About"}
        slogan={
          "Shaping Tomorrow's Energy Today: Datta Infra's Commitment to Sustainable Development"
        }
      />
      <section className="space">
        <div className="container">
          <div className=" justify-center text-center">
            <div className="c wow fadeInUp" data-wow-delay="0.3s">
              <div className="title-area">
                <span className="sec-subtitle">COMPANY OVERVIEW</span>
                <h2 className="sec-title h1">
                  Powering Progress Energizing Tomorrow
                </h2>
              </div>
            </div>
          </div>
          <div>
            {" "}
            <p className="pe-xxl-5 fs-md mb-xxl-4 pb-xl-2 mt-n1 justify">
              Datta Infra has established itself as a prominent leader in
              India&apos;s land acquisition and project development sector. Our
              core mission focuses on providing organized and sustainable
              solutions for land consolidation and transmission lines,
              bolstering India&apos;s renewable energy landscape.
            </p>
          </div>
          <div className="row">
            <div
              className="col-xl-6 mb-40 mb-xl-0 wow fadeInUp"
              data-wow-delay="0.4s"
            >
              <div className="img-bo x3 pt-5">
                <div className="img-1 img-600">
                  <img src="assets/img/about/5.jpeg" alt="about" />
                </div>

                {/* <div className="shape-dotted jump"></div> */}
              </div>
            </div>
            <div
              className="col-xl-6 align-self-center text-center text-xl-start wow fadeInUp"
              data-wow-delay="0.5s"
              style={{ marginTop: "50px" }}
            >
              <h6>Key Highlights:</h6>
              <br />
              <h6>RE Asset Portfolio:</h6>
              <p className="pe-xxl-5 fs-md mb-xxl-4 pb-xl-2 mt-n1 justify">
                Currently managing a robust portfolio of 3.5 GW in renewable
                energy assets. Datta Infra has a proven track record of
                executing successful projects, the company continues to expand
                its portfolio with an ongoing pipeline of 8000 MW of renewable
                energy parks.
              </p>
           
            <h6>Comprehensive Services:</h6>
            <p className="pe-xxl-5 fs-md mb-xxl-4 pb-xl-2 mt-n1 justify">
              Datta Infra excels in offering end-to-end solutions in the
              renewable energy sector, ranging from project development to
              Engineering, Procurement, and Construction (EPC) projects on a
              turnkey basis. Our team of seasoned professionals is dedicated to
              driving innovation and sustainability in infrastructure
              development. Datta Infra&apos;s commitment to quality is reflected
              through its ISO 14001:2015, ISO 9001:2015, and ISO 45001:2018
              certifications, showcasing its dedication to excellence in every
              project undertaken
            </p>
                   </div>

          </div>
          <div className="row">
          <div
            className="col-xl-6 mb-40 mb-xl-0 wow fadeInUp"
            data-wow-delay="0.4s"
          >
            <div className="img-bo x3 pt-5">
              <div className="img-1 img-600">
                <img src="assets/img/about/why-choose-1.jpeg" alt="about" />
              </div>

              {/* <div className="shape-dotted jump"></div> */}
            </div>
          </div>
          <div
            className="col-xl-6 align-self-center text-center text-xl-start wow fadeInUp"
            data-wow-delay="0.5s"
            style={{ marginTop: "50px" }}
          > 
          <h6>Specialized Civil Work and Survey Testing:</h6>
          <p className="pe-xxl-5 fs-md mb-xxl-4 pb-xl-2 mt-n1 justify">
            Utilizing advanced technologies such as Ramming Machine, TSS
            machines, Drone Survey Footage, Soil Testing, and DGPS Technology
            for efficient commercial installations.
          </p>

           
          <h6>Diversified Offerings:</h6>
          <p className="pe-xxl-5 fs-md mb-xxl-4 pb-xl-2 mt-n1 justify">
            Specializing in renewable energy solutions, we undertake the EPC
            of transmission lines, solar parks, wind parks, and provide
            operation and maintenance services. Our commitment to clean energy
            initiatives aligns with India&apos;s growing demand for
            sustainable development.
          </p>

          <h6>Future Ventures:</h6>
          <p className="pe-xxl-5 fs-md mb-xxl-4 pb-xl-2 mt-n1 justify">
            Future Ventures: As part of our growth strategy, Datta Infra is
            expanding into the Independent Power Producer (IPP) business.
            Building on our existing 150 MW of hybrid projects. This expansion
            underscores our proactive role in advancing India&apos;s clean
            energy landscape and promoting sustainable development nationwide.
          </p>            </div>

        </div>
        </div>
      </section>
      <WelcometoDatta />

      <div className="space-bottom">
        <div className="container">
          <div className="row gx-80 mb-1 pb-3 text-center text-xl-start justify-content-center justify-content-xl-start">
            <div
              className="col-md-4 col-lg-3 col-xxl-auto wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="counter-style1">
                <span className="counter-number h1">5+</span>
                <p className="counter-text">
                  Wind energy Projects in <br />
                  Barmer, Rajasthan
                </p>
              </div>
            </div>
            <div
              className="col-md-4 col-lg-3 col-xxl-auto wow fadeInUp"
              data-wow-delay="0.4s"
            >
              <div className="counter-style1">
                <span className="counter-number h1">50+</span>
                <p className="counter-text">
                  Projects Completion <br />
                  in India
                </p>
              </div>
            </div>
            <div
              className="col-md-4 col-lg-3 col-xxl-auto wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="counter-style1">
                <span className="counter-number h1">55,000+</span>
                <p className="counter-text">
                  Acres of land accquired <br />
                  for solar projects
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-7 col-xl-7 mb-40 mb-lg-0">
              <div className="img-box1 wow fadeInUp" data-wow-delay="0.6s">
                <div className="img-1">
                  <img src="assets/img/about/12_enhanced.jpg" alt="about" />
                </div>
                <div className="shape-dotted jump-reverse"></div>
              </div>
            </div>
            <div
              className="col-lg-5 col-xl-4 align-self-center offset-xl-1 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div class="list-style1">
                <ul class="list-unstyled">
                  <li>1 GW Solar Power Project -Rajasthan</li>
                  <li>500 MW - CPSU 1 &2 -Rajasthan</li>
                  <li>225 MW HYBRID PROJECT -Rajasthan</li>
                  <li>25 MW Solar Power Project -Odisha</li>
                  <li>100 MW PPA WITH TRANSMISSION LINE -chattisgarh</li>
                  <li>300 MW Solar PARK -Rajasthan</li>
                  <li>400 Kv 20 Km Transmission Line -Rajasthan</li>
                  <li>400 Kv 20 Km Transmission Line -Rajasthan</li>
                </ul>
              </div>
              {/* <div className="list-style1">
                <ul className="list-unstyled">
                  <li>CPSU 1 – 300 MW – DEVELOPER NTPC</li>
                  <li>CPSU 2 – 250 MW – DEVELOPER NTPC</li>
                  <li>225 MW TATA POWER GREEN (DEVELOPER) HYBRID</li>
                  <li>Orissa – 50 MW Private Park</li>
                  <li>Chattisgarh – 100 MW PPA with Transmission line</li>
                  <li>Tamil Nadu – 110 MW park with transmission </li>
                  <li>Gujarat- 100 MW </li>
                  <li>Maharashtra – 300 MW </li>
                  <li>300 MW – NHPC at Bikaner, Rajasthan</li>
                  <li>Leasing of 1500 acres land for TATA Power</li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <Howwework />

      <section className="overflow-hidden bg-light-1 space-top">
        <div className="container">
          <div className="title-area mb-3 pb-1 pb-5 text-center">
            <span className="sec-subtitle">WHY CHOOSE US</span>
            <h2 className="sec-title h1">
              We are Building a Sustainable Future
            </h2>
          </div>
          <div className="row gx-60 flex-row-reverse justify-content-between align-items-center">
            <div
              className="col-lg-5 col-xl-6 z-index-common wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="img-box7">
                <div className="img-1">
                  <img
                    src="assets/img/about/8_enhanced.jpeg"
                    alt="about thumbnail"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-7 col-xl-6 space py-xl-0 wow fadeInUp"
              data-wow-delay="0.4s"
            >
              <div
                className="accordion accordion-style2 space-bottom"
                id="faqVersion1"
              >
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Proven Track Record
                    </button>
                  </div>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqVersion1"
                  >
                    <div className="accordion-body">
                      <p>
                        {" "}
                        With years of experience and a track record of
                        successful projects, Datta Infra stands as a trusted
                        name in the renewable energy sector. Our history of
                        delivering India&apos;s largest solar projects and
                        securing orders for wind and hybrid projects, including
                        under prestigious schemes like KUSUM, demonstrates our
                        expertise and reliability.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <div className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Industry Leadership
                    </button>
                  </div>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#faqVersion1"
                  >
                    <div className="accordion-body">
                      <p>
                        Led by seasoned professionals, Datta Infra is at the
                        forefront of renewable project development, transmission
                        line construction, substations, and EPC projects on a
                        turnkey basis. Our specialization in renewable energy,
                        particularly solar and wind, sets us apart as leaders in
                        the field.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <div className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Commitment to Sustainability
                    </button>
                  </div>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#faqVersion1"
                  >
                    <div className="accordion-body">
                      <p>
                        At Datta Infra, we are committed to fostering
                        sustainability and advancing India&apos;s clean energy
                        landscape. Our dedication to developing over 1 GW of
                        renewable energy projects in the next two years as an
                        Independent Power Producer (IPP) underscores our
                        commitment to driving positive change.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <div className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      Expertise in Transmission Lines
                    </button>
                  </div>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#faqVersion1"
                  >
                    <div className="accordion-body">
                      <p>
                        Datta Infra exhibits unmatched expertise in transmission
                        line construction, having completed significant projects
                        such as a 400 KV S/C line for SJVNL&apos;s 1 GW project
                        and a 220 KV transmission line for Tata Power Renewable
                        Energy Limited. Our proficiency in this area ensures
                        seamless integration of renewable energy into the grid.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <div className="accordion-header" id="headingFive">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      Strategic Partnerships
                    </button>
                  </div>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#faqVersion1"
                  >
                    <div className="accordion-body">
                      <p>
                        Our strategic partnership with industry giants like Tata
                        Power demonstrates the trust and confidence placed in
                        Datta Infra&apos;s capabilities. Entrusted with EPC
                        services for solar projects in Rajasthan, our
                        partnership with Tata Power reflects their recognition
                        of our expertise and commitment to excellence.
                      </p>
                    </div>
                  </div>
                </div>{" "}
                <div className="accordion-item">
                  <div className="accordion-header" id="headingSix">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      Expansion and Growth
                    </button>
                  </div>
                  <div
                    id="collapseSix"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingSix"
                    data-bs-parent="#faqVersion1"
                  >
                    <div className="accordion-body">
                      <p>
                        Datta Infra&apos;s successful completion of EPC
                        assignments has led to further projects in key regions
                        such as Rajasthan, Tamil Nadu, and Maharashtra. Securing
                        contracts of 10 GW from corporate giants like Aditya
                        Birla, Tata, Engie, and PSUs like SJVN and NTPC,
                        underscores our commitment to growth and expansion.
                      </p>
                    </div>
                  </div>
                </div>{" "}
                <div className="accordion-item">
                  <div className="accordion-header" id="headingSeven">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven"
                      aria-expanded="false"
                      aria-controls="collapseSeven"
                    >
                      Reliability and Performance
                    </button>
                  </div>
                  <div
                    id="collapseSeven"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingSeven"
                    data-bs-parent="#faqVersion1"
                  >
                    <div className="accordion-body">
                      <p>
                        We take pride in our ability to deliver projects within
                        or ahead of timelines, demonstrating reliability and
                        professionalism in every aspect of our work. Our
                        confirmed order book exceeding Rs.1700 crores speaks
                        volumes about our performance and credibility in the
                        industry.
                      </p>
                    </div>
                  </div>
                </div>{" "}
                <div className="accordion-item">
                  <div className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEight"
                      aria-expanded="false"
                      aria-controls="collapseEight"
                    >
                      Contribution to Sustainable Development
                    </button>
                  </div>
                  <div
                    id="collapseEight"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingEight"
                    data-bs-parent="#faqVersion1"
                  >
                    <div className="accordion-body">
                      <p>
                        By choosing Datta Infra, clients contribute to the
                        development of renewable energy infrastructure and ease
                        project setup challenges for corporate and public sector
                        clients. Together, we are driving positive change and
                        making a meaningful impact on the environment and
                        society.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <CompanyOverview/>
    <ProjectsCompleted1 />
    <WorkFlow/>
    <WhyChooseUs/>
     */}
      <Clients />
    </>
  );
};

export default About;
