import React from 'react'

const BreadCrum = (props) => {
  return (
    <>
        <div className="breadcumb-wrapper " style={{ backgroundImage: 'url(/assets/img/breadcumb/breadcumb-bg.jpg)' }}>
        <div className="container z-index-common">
            <div className="breadcumb-content">
                <h1 className="breadcumb-title">{props.title}</h1>
                <p className="breadcumb-subtitle">{props.slogan}</p>
            </div>
        </div>
    </div>
    <div className="breadcumb-menu-wrap">
        <div className="container">
            <ul className="breadcumb-menu">
                <li><a href="/"><i className="fas fa-home-lg"></i>Home </a></li>
      {        props.child ?
          <li> <a href={`/${props.parent}`}>{props.parenttitle}</a></li> :
          <li> {props.title}</li> 
        }
              {props.child && <li> {props.child}</li>}
            </ul>
        </div>
    </div>
    
    </>
  )
}

export default BreadCrum